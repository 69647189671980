/* =============================================================================
	App specific CSS file.
========================================================================== */

/* universal */

@font-face {
	font-family: 'CirceWebLight';
	src: url('../fonts/CRC35__W.eot');
	src:
		 url('../fonts/CRC35__W.woff') format('woff'),
		 url('../fonts/CRC35__W.ttf') format('truetype'),
		 url('../fonts/CRC35__W.svg#CirceWeb-Light') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'CirceWebRegular';
	src: url('../fonts/CRC55__W.eot');
	src:
		 url('../fonts/CRC55__W.ttf') format('truetype'),
		 url('../fonts/CRC55__W.woff') format('woff'),
		 url('../fonts/CRC55__W.svg#CirceWeb-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}

/*
--------------------------------------------------
RESET STYLES
--------------------------------------------------
*/

html, body, div, span, p, a, dl, dt, dd, ol, ul, li,
h1, h2, h3, h4, h5, h6,
form, input, button, select, button, label,
iframe, table, caption, tbody, tfoot, thead, tr, th, td {
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	/*text-shadow:-1px -1px 1px rgba(255,255,255,0.2), 1px 1px 1px rgba(255,255,255,0.2);*/
	text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}

.nav ul, ul.nav, ul.links {list-style: none;}

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

input:-moz-ui-invalid {
	box-shadow: none;
}

input, select { vertical-align: middle; }
input, button, a, .options__size {
	outline: none;
}
button {
	border: none;
	background: none;
}

iframe, img { border: none; }
b { font-weight: bold; }

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	border: none;
}

input::-ms-clear {
	display: none;
}

.clearfix:after {
	content: "";
	display: block;
	height: 0;
	overflow: hidden;
	clear: both;
}

html {
	min-height: 100%;
	height: 100%;
}

body {
	min-height: 100%;
	height: 100%;
	font-family: 'CirceWebRegular', sans-serif;
	overflow-x: hidden;
}

textarea {
	font-family: 'CirceWebRegular', sans-serif;
	resize: vertical;
}

a {
	text-decoration: none;
}

body:not(.mobile_version) .mobile {
	display: none !important;
}

.body_old {
	padding-top: 60px;
	padding-bottom: 33px;
	min-height: 100%;
	position: relative;
}

section {
	overflow: auto;
}


.blocked_scrolling {
	overflow: hidden;
	position: fixed;
}

.container-narrow{
	margin: 0 auto;
	max-width: 580px;	
}

.container-narrow.profile_index {
	max-width: inherit;
}

.profile_index_wrap {
	float: right;
	width: 850px;
}

.profile_title {
	margin-bottom: 5px;
}

.panels_left {
	display: inline-block;
	float: left;
	width: 540px;
	margin-right: 10px;
}

/* Forms */

.navbar-form input[type="text"],
.navbar-form input[type="password"] {
	width: 180px;
}

.panel-body table .table-header-credit{
	padding-left: 58px;
}

.panel-body .pay-text{
	margin: 14px 0 10px;
    padding: 15px;
}

.form-register {
	width: 55%;
}

.form-register__subtitle {
	margin-top: 50px;
}

.form-register .form-control {
	position: relative;
	font-size: 16px;
	height: auto;
	padding: 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.pre {
	white-space: pre;
}

/* footer */

footer {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	padding-top: 5px;
	color: #999999;
}

footer a {
	color: #999999;
}

footer p {
	float: right;
	margin-right: 25px;
}

footer ul {
	list-style: none;
}

footer ul li {
	float: left;
	margin-left: 10px;
}

footer .company {
	float: left;
	margin-left: 25px;
}

footer .footer-nav {
	float: right;
	margin-right: 25px;
	list-style: none;
}

.footer_terms_link {
	position: relative;
	top: 17px;
}

/* Home */

.checklist {
	list-style: none;
	font-size: 20px;
}

.checklist li:before {
	content: "\2713 \0020";
}

h3.panel-title {
	font-size: 20px;
}

/* Demo */

.demo_figure {
	width: 500px;
	height: 500px;
	position: relative;
	overflow: hidden;
}

.demo_preview_bg {
	display: none;
	background-size: cover;
	width: 100%;
	height: 100%;
	opacity: .5;
	-webkit-filter: blur(10px);
	filter: blur(10px);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.demo_preview {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	max-width: 100%;
	max-height: 100%;
}

.demo_upload {
	position: absolute;
	top: 25px;
	right: 25px;
	bottom: 25px;
	left: 25px;
	border: 1px dashed #000;
}

.demo_upload__btn {
	width: 85px;
	height: 83px;
	text-align: center;
	line-height: 87px;
	font-size: 28px;
	cursor: pointer;
	position: absolute;
	top: 73px;
	right: 0;
	left: 0;
	margin: auto;
	z-index: 1;
	font-size: 4em;
}


.btn {
	display: inline-block;
	line-height: 32px;
	padding: 0 18px;
	color: inherit;
	cursor: pointer;
	border-radius: 0;
	text-align: center;
}

.btn-plain {
	border: 1px solid #fff;
}

.desktop_version .btn-plain:hover {
	border-color: #1a1a1a;
	box-shadow: 0 0 0 5px #7000ff;
	z-index: 100;
}

.desktop_version .btn-plain-inset:hover {
	box-shadow: 0 0 0 5px #888 inset;
}

.demo_upload__text {
	font-size: 24px;
	position: absolute;
	bottom: 242px;
	text-align: center;
	width: 100%;
}

.demo_upload__input_wrap {
	width: 245px;
	height: 38px;
	position: absolute;
	right: 0;
	bottom: 205px;
	left: 0;
	margin: auto;
}

.demo_upload__input_wrap::after {
	content: '';
	display: block;
	height: 1px;
	position: absolute;
	right: 0;
	bottom: 8px;
	left: 0;
}

.demo_upload__input {
	width: 100%;
	height: 28px;
	padding: 0 31px 0 15px;
	font-size: 22px;
}

.demo_upload__formats {
	font-size: 12px;
	color: #888;
	position: absolute;
	bottom: 15px;
	right: 0;
	left: 0;
	text-align: center;
}

.captcha_form {
	position: absolute;
	bottom: 110px;
	left: 110px;
	text-align: center;
}

#remindForm .auth_form__captcha {
	margin-top: 25px;
}

#remindForm .auth_form__captcha .g-recaptcha div {
	margin: 0 auto;
}

.g-recaptcha_wrap {
	margin-top: 20px;
}


.submit_button {
	font-size: 19px;
	position: absolute;
	bottom: 60px;
	width: 10vw;
	height: 40px;
	left: 180px;
}

.captcha_form.moved {
	bottom: -100px;
	left: -90px;
}

.submit_button.moved {
	bottom: -80px;
	left: 240px;
	width: 100px;
}

.demo_results .demo_figure__bg,
.demo_results .demo_info__title,
.demo_results .demo_info__text,
.demo_results .demo_upload__helper,
/* .demo_results .footer-demo .footer__contact, */
.demo_results .demo_socials .share_btns,
.demo_results .share-static,
.demo_results .demo_socials__title {
	display: none !important;
}

.demo_results .demo_preview_bg,
.demo_results .demo_preview,
.demo_results .demo_suggest,
.demo_results .demo_info__tip,
.demo_results .demo_share_title,
.demo_results .esinfo,
.demo_results .demo_subscr,
.demo_results .share-dynamic {
	display: block;
}

.demo_results .demo_result,
.demo_results.desktop_version .demo_info__share,
.demo_results .demo_socials__title-subst {
	display: inline-block;
}

.demo_results .demo_follow,
.demo_results.desktop_version .demo .footer__contact,
.demo_results.desktop_version .demo .footer__copyright {
	margin-top: 0;
}

.demo_results .demo_upload {
	height: 83px;
	margin-top: 20px;
	margin-left: 88px;
	padding: 20px 0 0 20px;
	border-width: 1px 1px 1px 0;
	position: relative;
	top: 0;
	left: 0;
}

.demo_results .demo_upload__btn {
	top: -1px;
	right: auto;
	left: -89px;
}

.demo_results .demo_upload__text {
	width: auto;
	font-size: 18px;
	margin-left: 5px;
	position: static;
	display: inline-block;
	vertical-align: top;
	text-align: left;
}

.demo_results .demo_upload__input_wrap {
	width: 90px;
	height: auto;
	margin-left: 5px;
	position: static;
	display: inline-block;
}

.demo_results .demo_upload__input_wrap::after {
	content: none;
}

.demo_results .demo_upload__input {
	font-size: 18px;
	padding: 0;
}

.demo_results .demo_upload__formats {
	left: 25px;
	text-align: left;
}

.demo_upload__formats.go {
	animation-name: wrong_size;
	animation-duration: 1200ms;
	animation-timing-function: linear;
}

@keyframes wrong_size {
	from {
	transform: translateX(0);
	}
	10% {
	transform: translateX(-10px);
	color: #888;
	}
	20% {
	transform: translateX(10px);
	}
	30% {
	transform: translateX(-10px);
	}
	40% {
	transform: translateX(10px);
	}
	50% {
	transform: translateX(-10px);
	}
	60% {
	transform: translateX(10px);
	}
	70% {
	transform: translateX(-10px);
	}
	80% {
	transform: translateX(10px);
	color: #888;
	}
	90% {
	transform: translateX(-10px);
	}
	to {
	transform: translateX(0);
	}
}

.demo_result {
	display: none;
	width: 100%;
}

.demo_result__score {
	font-size: 72px;
	line-height: .87;
}

.demo_result__descr {
	font-size: 20px;
	line-height: 1.3;
	position: relative;
}

.demo_suggest__list{
	height: 420px;
	overflow-y: scroll;
}

.demo_suggest__descr {
	padding-top: 20px;
	padding-bottom: 10px;
	font-size: 18px;
}

.demo_suggest__item {
	padding: 2px 10px;
}

.nav__username {
	padding: 15px 0;
	color: #9d9d9d;
	line-height: 20px;
	display: block;
	position: relative;
}

.demo_upload__btn__icon {
	bottom: 0;
	height: 30px;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 40px;
}

/* SERVICE MESSAGES */

.service_msgs_wrap {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 10000;
}

.service_msg {
	width: 625px;
	padding: 13px 25px;
	margin: 15px auto 0;
	background: #ffffbe;
	box-shadow: 0 5px 12px rgba(0,0,0,.2);
	color: #000;
	font-size: 16px;
	position: relative;
	z-index: 99999;
	text-align: center;
}

.service_msg.error {
	color: #b10009;
	background-color: #fde4e1;
}

.service_msg__close {
	font-size: 30px;
	color: #cccc99;
	position: absolute;
	top: 5px;
	right: 5px;
	line-height: 16px;
	cursor: pointer;
}

.service_msg__close:hover {
	color: #999;
}

.radio-inset {
	padding-left: 20px;
}



.dropzone_file {
	display: none !important;
	opacity: 0;
}

.file_dragging .dropzone_file {
	display: block !important;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	z-index: 99999;
}

.dnd_holder {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	z-index: 99998;
	display: none;
	background: #1a1a1a;
	color: #fff;
	font-size: 24px;
	opacity: .9;
}

.dnd_holder::before {
	content: "";
	background-color: #1a1a1a;
	display: block;
	position: absolute;
	top: 90px;
	right: 30px;
	bottom: 100px;
	left: 30px;
	border: dashed 2px #fff;
}

.dnd_holder__inner {
	position: absolute;
	height: 52px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	text-align: center;
}

.file_dragging .dnd_holder {
	display: block;
}




.about_nav {
	height: 0;
	position: absolute;
	top: 860px;
	right: -130px;
	margin: auto;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	transform-origin: center;
	z-index: 100;
	color: #bcbcbc;
}

.about_nav.fixed {
	position: fixed;
	top: 0;
	bottom: 0;
}

.about_nav__item {
	color: currentColor !important;
	font-size: 20px;
	transition: color .2s linear;
	text-decoration: none !important;
}

.about_nav__item:hover {
	color: #1a1a1a !important;
	transition: color .2s linear;
}

.about_nav__item.active,
.about_nav__item-reversed.active,
.about_nav__item-reversed.active ~ .about_nav__item:hover  {
	color: #1a1a1a !important;
}

.link_green_on_black {
	color: #00eebb;
	display: inline-block;
	line-height: 1;
	border-bottom: 1px solid #008870;
	text-decoration: none;
	cursor: pointer;
}

.link_green_on_black:visited {
	color: #008870;
	border-color: #008870;
}

.link_green_on_black:hover {
	color: #00eebb;
	border-color: #00e3bb;
}

.link_green_on_black:active {
	color: #00b08a;
	border-color: #008870;
}

.dashed_link_full_green {
	color: #00eebb;
	display: inline-block;
	line-height: 1;
	border-bottom: 1px dashed #008870;
	text-decoration: none;
	cursor: pointer;
}

.dashed_link_full_green:visited {
	color: #008870;
	border-color: #008870;
}

.dashed_link_full_green:hover {
	border-color: #00e3bb;
}

.dashed_link_full_green:active {
	color: #00b08a;
	border-color: #008870;
}

.wrap-api {
	position: relative;
	background-color: #1a1a1a;
	min-height: 100%;
}

.wrap-api ul {
	padding: 0;
}

.wrap-api a:hover {
	text-decoration: none;
}

.epcontainer {
	max-width: 1440px;
	margin-right: auto;
	margin-left: auto;
	padding-left: 30px;
	padding-right: 30px;
	box-sizing: border-box;
}

.epcontainer-hires {
	max-width: none;
}

.epcontainer-hires .epcontainer {
	max-width: none;
}

.epcontainer-hires.account_header {
	background: #1a1a1a;
}

.about_block {
	background-color: #fff;
	position: relative;
}

.about_block-highlight {
	background-color: #7000ff;
}

.about_block-screenheight {
	min-height: 600px;
	height: 100vh;
	max-height: 1080px;
}

.api_block {
	padding-top: 50px;
}

.header-index {
	position: relative;
	z-index: 1000;
	padding: 25px 0;
	background: #1a1a1a;
}

.header-index:after {
	content: "";
	display: block;
	height: 0;
	overflow: hidden;
	clear: both;
}

.header__logo {
	float: left;
	margin-left: 0;
	width: 178px;
	height: 23px;
	background-image: url('../i/logo-white.svg');
	background-size: 100%;
	background-position: 0 3px;
	background-repeat: no-repeat;
	transition: all .3s;
}

.header__copyright {
    margin-top: 164px;
    float: right;
    font-size: 18px;
    position: relative;
}

.current_page {
	display: inline-block;
	vertical-align: top;
	height: 21px;
	line-height: 22px; 
	margin: 0 0 0 11px;
	border: 1px solid #7000ff;
	text-transform: uppercase;
	color: #fff;
	font-size: 14px;
	background: #7000ff;
	letter-spacing: 2px;
	padding: 0 10px;
}


.menu {
	list-style-type: none;
	float: right;
	font-size: 0;
	position: relative;
	z-index: 1000;
}

.menu--index_page {
 	display: none !important;
}

.menu__list {
	
}

.menu .header__logo {
	display: none;
}

.header-search .menu {
	background: #1a1a1a;
}

.menu:not(.opened) .menu__link-signup {
	border: solid 1px #7000ff !important;
}

.menu:not(.opened) .menu__link-signup:after {
	display: none !important;
}

.menu:not(.opened) .menu__link-signup:hover {
	box-shadow: 0 0 0 calc(0.35vw - 1px) #7000ff
}

.body_microstock .menu:not(.opened) .menu__link-signup {
	border: solid 1px rgba(242,242,242,0.5)  !important;
}

.body_microstock .menu:not(.opened) .menu__link-signup:hover {
	box-shadow: 0 0 0 calc(0.35vw - 1px) rgba(242,242,242,0.5)  !important;
}

.body_free  .menu:not(.opened) .menu__link-signup {
	border: solid 1px #00eebb !important;
}

.body_free .menu:not(.opened) .menu__link-signup:hover {
	box-shadow: 0 0 0 calc(0.35vw - 1px) #00eebb !important;
}

.wrap-xsive .menu:not(.opened) .menu__link-signup {
	border: solid 1px #606060 !important;
}

.wrap-xsive .menu:not(.opened) .menu__link-signup:hover {
	box-shadow: 0 0 0 calc(0.35vw - 1px) #606060 !important;
}

.menu__list {
	z-index: 1;
}

.menu__item {
	font-size: 18px;
	color: #fff;
	margin-left: 9px;
	display: inline-block;
	position: relative;
}

.menu__item-signin {
	display: none;
}

.menu__link {
	color: #fff;
	font-size: 18px;
	padding: 0 11px;
	background: none;
	border: none;
}

.menu__link-signup {
	cursor: pointer;
}

.menu__link:hover {
	color: #fff;
	text-decoration: none;
	
}

.menu__submenu {
	display: none;
	position: absolute;
	padding-top: 7px !important;
	background: #1a1a1a;
  margin-left: -10px;
}

.menu__item:hover .menu__submenu {
	display: block;
}

.menu__subitem {
	display: block;
	white-space: nowrap;
	margin-bottom: 7px;
	padding-left: 10px;
}

.menu__subitem .menu__link-sub {
	font-size: 16px;
}



.menu__show,
.menu__hide {
	display: none;
}




.menu__show {
	width: 65px;
	height: 62px;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
}

.header-search .menu__show {
	background: #1a1a1a;
}

.menu__show:hover .menu__show__icon {
	color: #00e2bb;
}

.menu__show__icon {
	width: 23px;
	height: 13px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	color: #fff;
	border: solid currentColor;
	border-width: 1px 0;
}

.menu__show__icon::after {
	content: '';
	display: block;
	height: 1px;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	background: currentColor;
}

.desktop_version .menu__hide {
	float: right;
	width: 65px;
	height: 62px;
	line-height: 62px;
	cursor: pointer;
	position: relative;
	text-align: center;
}

.desktop_version .menu__hide__icon {
	font-size: 16px;
	color: #ff6565;
}

.desktop_version .menu__hide:hover .menu__hide__icon {
	color: #ee4242;
}


.link-animated {
	position: relative;
}

.link-animated::after {
	content: '';
	width: 5px;
	height: 5px;
	background: #7000ff;
	position: absolute;
	top: 64%;
	left: 0;
	z-index: auto;
	transition: width ease .5s;
}

.link-animated:hover::after {
	width: 100%;
}

@media screen and (min-width: 1025px) {
	.menu__username {
		text-transform: lowercase;
	}
}





.api_main {
	color: #fff;
	padding-top: 40px;
	padding-bottom: 30px;
}

.api_main__title {
	font-size: 72px;
	line-height: 1.111;
}

.api_main__title_string {
	display: inline-block;
	line-height: 1.08em;
	padding: 0 4px;
	background: #7000ff;
}

.about_text {
	font-size: 24px;
	line-height: 1.42;
}
.api_main__decsr {
	max-width: 825px;
	margin-top: 40px;
}

.api_main__btn {
	width: 100%;
	max-width: 400px;
	line-height: 80px;
	font-size: 24px;
}

.api_main__btn.margin {
	margin-top: 50px;
}

.api_main__btn:hover {
	color: #fff;
}

.press_title {
	font-size: 64px;
	line-height: 1.111;
}

.press_items {
	margin-top: 40px;
	font-size: 0;
}

.press_item {
	display: inline-block;
	width: 47%; 
	margin-bottom: 50px;
	vertical-align: top;
	max-width: 460px;
}

.press_item__title {
	font-size: 36px;
	padding: 20px 0 10px;
}

.press_descr {
	margin-top: 20px;
	max-width: 760px;
}

.press_item__note, .press_item__text {
	max-width: 460px;
	position: relative;
	font-size: 18px;
	line-height: 1.444;
}

.press_item__text {
	font-size: 24px;
}

.desktop_version .press_item:nth-child(even) {
	margin-left: 6%;
}

.press_item__icon {
	height: 150px;
}

.press_mission {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
}

.press_mission__text {
	font-size: 24px;
	color: #fff;
	text-align: center;
	margin: auto;
	position: relative;
	top: -20px;
}

.press_mission__quoute {
	width: 80vw;
}

.press_mission__author {
	margin-top: 3vw;
	text-align: right;
	font-size: 14px;
}

.press_item__text-card {
	min-height: 15vw;
	border: 2px solid #7000ff;
	padding: 2vw 2.5vw;
}

.press_item__text-card:hover,
.press_item.hovered .press_item__text-card {
	background: #7000ff;
	color: #fff;
}


.api_comments {
	background-color: #1a1a1a;
	position: relative;
	
}

.api_comments__slides {
	color: #fff;
}

.api_comments__slide {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	height: 100vh;
	max-width: 800px;
	margin: auto;
	position: relative;
	padding: 0 10px;
	position: absolute;
	right: 0;
	left: 0;
	opacity: 0;
}

.api_comments__slide__title {
	font-size: 48px;
	margin-bottom: 30px;
}

.api_comments__slide__text {
	font-size: 20px;
}

.api_comments__slide__author {
	font-size: 20px;
	float: right;
	margin-top: 25px;
}

.api_comments__slide__author__link {
	color: inherit;
	border-bottom: 1px dashed;
	color: #1ddfad;
}

.api_comments__slide__author__link:hover {
	color: #1ddfad;
}

.api_comments__btns {
	font-size: 0;
	position: absolute;
	right: 0;
	left: 0;
	bottom: 60px;
	text-align: center;
	z-index: 20;
}

.api_comments__btn {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin: 0 10px;
	cursor: pointer;
	position: relative;
}

.api_comments__btn::after {
	content: '';
	width: 5px;
	height: 5px;
	background: #fff;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}

.api_comments__btn.active::after,
.api_comments__btn:hover::after {
	background: #1ddfad;
}


.api_price {
	overflow: hidden;
	margin-top: 50px;
	list-style-type: none;
	margin-bottom: 40px;
	padding-right: 80px;
}

.api_price__item {
	float: left;
	min-height: 600px;
	box-sizing: border-box;
	width: 25%;
	border: 1px solid;
	border-width: 1px 1px 1px 0;
	padding: 1% 2%;
	position: relative;
	color: #000;
}

.api_price__item:first-child {
	border-left-width: 1px;
}

.api_price__item__name {
	font-size: 32px;
	color: inherit;
}

.api_price__item__descr {
	font-size: 14px;
	color: #999;
}

.api_price__item__info {
	font-size: 24px;
	position: absolute;
	bottom: 50px;
	color: inherit;
}

.api_price__item__info li {
	list-style: disc inside;
}

.api_price__item__arr {
	position: absolute;
	right: 30px;
	bottom: 20px;
	width: 20px;
	transition: all .2s;
}

.api_price__item:hover,
.api_price__item.hovered {
	background: #7000ff;
	color: #fff;
}

.api_price__item:hover .api_price__item__descr,
.api_price__item.hovered .api_price__item__descr {
	color: #fff;
}

.api_price__item:hover .api_price__item__arr,
.api_price__item.hovered .api_price__item__arr {
	stroke: #fff;
	transform: rotate(90deg);
}

.api_main__contactus {
	margin: 60px 0 140px;
	line-height: 70px;
	width: 200px;
	border-color: #1a1a1a;
	font-size: 24px;
}

.api_main__contactus:hover {
	color: var(--back-color);
}

.press_item__btn {
	margin: 0;
	margin-top: 2vw;
	color: #333;
}

.press_item__btn:hover {
	color: #333;
}

.desktop_version .about_main,
.desktop_version .about_contacts,
.desktop_version .press_main,
.desktop_version .press_contacts {
	box-sizing: border-box;
	padding-bottom: 90px;
}

.press_contacts {
	background: #1a1a1a;
	color: #fff;
}

.press_contacts__title {
	padding-top: 60px;
	font-size: 187px;
	line-height: 1;
	color: #fff;
	letter-spacing: -10px;
}

.press_contacts__blocks {
	margin: 30px 0 80px;
}

.press_contacts__block {
	display: inline-block;
	vertical-align: top;
}

.press_contacts__block + .press_contacts__block {
	margin-left: 85px;
}

.press_contacts__block__info {
	margin-top: 20px;
	font-size: 24px;
	line-height: 1.4;
}

.about_contacts__email_link {
	display: inline-block;
	color: #1ddfad;
	border-bottom: 2px solid rgba(0,227,187,.2);
	height: 1.01em;
	font-size: 48px;
	line-height: 1.125;
}

.about_contacts__email_link:hover,
.about_contacts__email_link:active {
	color: #1ddfad;
	border-bottom-color: rgb(0,227,187);
}

.api_request_btn {
	width: 100%;
	max-width: 320px;
	line-height: 80px;
	font-size: 24px;
	margin-bottom: 70px;
}

.api_request_btn:hover {
	color: #fff;
}

.footer {
	position: absolute;
	right: 0;
	bottom: 35px;
	left: 0;
	margin: auto;
	color: #fff;
	z-index: 10;
	font-size: 18px;
}

.footer__content {
	position: relative;
	box-sizing: border-box;
}

.footer__fasie {
	position: absolute;
	top: 35px;
	left: 30px;
}


.footer__contact {
	margin-top: 14px;
	float: left;
}

.footer__copyright {
	margin-top: 14px;
	float: right;
	margin-right: 50px;
	font-size: 18px;
	position: relative;
}

.footer__copyright::before,
.header__copyright::before {
	content: '';
	width: 5px;
	height: 5px;
	display: inline-block;
	vertical-align: baseline;
	margin-right: 6px;
	background: #fff;
}


.footer__link {
	color: #fff;
	border-bottom: 1px dashed #00eebb;
	display: inline-block;
}

.socials_wrap {
	float: left;
	margin-right: 20px;
}

.socials {
	vertical-align: middle;
}


.footer__menu_list {
	overflow: hidden;
	list-style: none;
	margin-top: 17px;
	display: block;
}

.footer__menu_item {
	float: left;
	display: inline-block;
	margin-right: 24px;
	/*font-size: 18px;*/
}

.footer__menu_link {
	cursor: pointer;
}

.about_contacts .footer__copyright::before,
.press_contacts .footer__copyright::before {
	background-color: #7000ff;
}

.socials_wrap {
	float: left;
	margin-right: 20px;
}

.socials {
	vertical-align: middle;
}

.share_btns {
	font-size: 0;
}

.share_btn {
	display: inline-block;
	width: 35px;
	height: 35px;
	margin-left: -1px;
	border: 1px solid #fff;
	color: #fff;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	position: relative;
	z-index: 1;
}

.share_btn:hover {
	border-color: #1a1a1a;
	z-index: 2;
	color: #fff;
}

.share_btn::before {
	line-height: 35px !important;
}



/*
--------------------------------------------------
$MODALS
--------------------------------------------------
*/

.modal,
.modal-react {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	z-index: 10000;
	overflow-y: scroll;
	display: block;
}

.modal_overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	background: rgba(29, 29, 29,.89);
}

.modal-auth {
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-auth:not(.modal-auth-side) .modal_window {
	margin-top: 0;
}


.modal-auth .modal_overlay,
.modal-info .modal_overlay,
.modal-submit .modal_overlay {
	background: #1a1a1a;
}

.modal_close {
	cursor: pointer;
	font-size: 20px;
	height: 40px;
	line-height: 40px;
	position: absolute;
	right: 10px;
	text-align: center;
	top: 14px;
	width: 40px;
}

.modal-auth .modal_close,
.modal-info .modal_close,
.modal-submit .modal_close {
	color: #ff6565;
	font-size: 30px;
}

.modal_window {
	/* display: table; */
	margin: 129px auto 0;
	background: #fff;
	position: relative;
	box-sizing: border-box;
}

.modal-auth .modal_window,
.modal-info .modal_window {
	background: transparent;
	border: solid 1px #ffffff;
	color: #fff;
}

.modal-info .modal_window {
	width: 410px;
	height: 410px;
	padding: 25px 23px;
	border: solid 1px #ffffff;
	color: #fff;
	background: transparent;

}

.modal-login .modal_window,
.modal-remind .modal_window {
	padding: 50px 60px 0px;
	width: 410px;
	height: 530px;
}

.modal_login_401 {
	display: flex !important;
}

.modal-remind .modal_window {
	height: 390px;
}

.modal-reset .modal_window {
	padding: 20px 60px 0px;
	width: 480px;
	height: 380px;
}

.modal-register .modal_window,
.modal-callback .modal_window {
	padding: 50px 60px 0px;
	width: 480px;
	height: 580px;
}

.modal-register .modal_window,
.modal-login .modal_window {
	width: 400px;
	height: 580px;
}

.modal_errors {
	color: #ff6565;
	padding-bottom: 15px;
}

.modal-register .modal_window .form_row-mid {
	width: 100%;
}

.modal-register .modal_regitster_success {
	height: 392px;
}

.form_helper {
	padding-top: 5px;
  float: left;
}

.form_helper_text {
	display: inline-block;
}

.modal_regitster_success button {
	width: 100%;
	color: #0eb;
	font-size: 14px;
}

.modal_success_ok {
	border: 1px solid #0eb;
	height: 50px;
	margin-top: 22px;
}

.modal_success_ok:hover {
	border-color: #7000ff;
}

.modal_success_text {
	font-size: 18px;
}

.modal-callback .modal_window {
	height: 510px;
}

.modal_title {
	font-size: 28px;
	font-size: 2.8rem;
	margin-bottom: 25px;
	text-align: center;
}

.modal-auth .modal_title {
	color: #fff;
	font-family: 'CirceWebLight';
	font-size: 32px;
}

.modal-register .modal_title {
	font-size: 32px;
	text-align: unset;
}

.modal_title-other {
	color: #ddd;
}

.modal_title-other .js_modal_btn {
	line-height: inherit;
}

.modal_title-other a:hover {
	color: #aaaaaa;
	border-bottom: none;
}

.auth_form {

}



.auth_form__field_name {
	position: absolute;
	color: #888;
	top: 12px;
	left: 10px;
	font-size: 18px;
	pointer-events: none;
	transition: all 0.1s linear;
}

.form_row-mid .auth_form__field_name {
	font-size: 14px;
}

.form_row_wide textarea, 
.form_row_wide select,
.form_row select {
	width: 100%;
	background: #1a1a1a;
	color: #fff;
	padding: 10px 10px;
	font-size: 14px;
}

.auth_form__input {
	width: 100%;
	height: 48px;
	padding: 17px 10px 4px 9px;
	border: none;
	border-radius: 0;
	box-shadow: 0 0 0 1px #ccc;
	box-sizing: border-box;
	background: transparent;
	color: #fff;
	border-bottom: none;
	font-size: 18px;
	position: relative;
	z-index: 1;
	-webkit-appearance: none;
	/*transition: all 0.1s linear; */
}


.auth_form__input.error {
  box-shadow: 0 0 0 1px #ff6565;
  width: calc(100% - -20px);
  margin-left: -10px;
  z-index: 30;
}

.form_row-mid.error {
	z-index: 1000;
}

.form_row-mid.error .auth_form__input {
    box-shadow: 0 0 0 1px #ff6565;
    z-index: 30;
}

.form_row-mid .auth_form__input {
	height: 42px;
}

.auth_form__input:focus {
	/*border-bottom: 1px solid #6400e5 !important;*/
}

.auth_form__input:-webkit-autofill {
	-webkit-box-shadow: inset 0 0 0 50px #1a1a1a, 0 0 0 1px #ccc !important;
	-webkit-text-fill-color: #fff;
}

.auth_form__textarea {
	height: 70px;
	resize: none;
}

.remind__input_hide {
	display: none;
}

.auth_form__remind {
	text-align: center;
  width: 100%;
  font-size: 18px;
}

.auth_form__remind button, .resend_link {
	color: #00eebb;	 
  margin-top: 20px;
  border-bottom: 1px dashed #008870;
  cursor: pointer;
}

.checkbox-transparent.error {
	border: 1px solid #ff6565;
}

.show_pwd_btn {
	width: 33px;
	height: 14px;
	display: block;
	/* background: url('../i/icon_eye.png') no-repeat 0 -13px; */
	cursor: pointer;
	position: absolute;
	top: -6px;
	right: 10px;
	bottom: 0;
	font-size: 14px;
	margin: auto;
	z-index: 2;
}

.show_pwd_btn:hover {
	color: #bd99e5;
}

.show_pwd_btn.active {
	color: #6400e5;
}

input[type=password]::-ms-reveal{
	display: none;
}



.form_row {
	width: 100%;
	position: relative;
	z-index: 1;
	margin-top: 1px;
	padding-left: 1px;
	float: left;
}

.form_row-mid {
	width: 50%;
	float: left;
	box-sizing: border-box;
}

.form_row:hover {
	z-index: 2;
}

.form_row:hover input {
	box-shadow: 0 0 0 5px #6400e5;
	z-index: 2;
}

.form_row:focus:after {
	display: none;
}

.auth_form__input:focus + .auth_form__field_name,
.auth_form__input.not_empty + .auth_form__field_name {
	top: 7px;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.auth_form__input:hover {
	
}

.auth_form__checkboxes_wrap {
	margin-top: 11px;
}

.auth_form__checkbox {
	display: inline-block;
	margin-top: 9px;
}

.auth_form__input_checkbox {
	opacity: 0;
	position: absolute;
}

.auth_form__link {
	color: #1feead;
}

.auth_form__link:hover {
	text-decoration: none;
} 

.checkbox-standart {
	width: 28px;
	height: 28px;
	display: inline-block;
	background: #fff;
	border: 1px solid #cccccc;
	box-sizing: border-box;
	position: relative;
	float: left;
}

.checkbox-standart:hover {
	border-color: #aaaaaa;
}

input:checked+.checkbox-standart span {
	display: block;
	color: #6400e5;
	font-size: 12px;
}

.checkbox-standart span {
	display: none;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 4px;
	left: 10px;
}

.checkbox-transparent {
	width: 30px;
	height: 30px;
	display: inline-block;
	background: transparent;
	border: 1px solid #ddd;
	box-sizing: border-box;
	position: relative;
	float: left;
	cursor: pointer;
}

.checkbox-transparent .tick {
	position: absolute;
	width: 30px;
	height: 30px;
	top: -5px;
	right: -6px;
	display: none;
}

.checkbox-transparent .tick:before,
.checkbox-transparent .tick:after {
	content: '';
	display: block;
	background: #ddd;
	width: 5px;
	position: absolute; 
}

.checkbox-transparent .tick:before {
	height: 29px;
	-webkit-transform: rotate(40deg);
	transform: rotate(40deg);
	top: -1px;
	right: 8px;
}

.checkbox-transparent .tick:after {
	height: 17px;
	-webkit-transform: rotate(-41deg);
	transform: rotate(-41deg);
	top: 10px;
	left: 5px;
}

.checkbox-transparent:hover .tick:before,
.checkbox-transparent:hover .tick:after {
	background: #6400e5;
}


input:checked + .checkbox-transparent .tick {
	display: block;
	color: #6400e5;
	font-size: 12px;
}

.auth_form__checkbox_title {
	font-size: 14px;
	line-height: 18px;
	margin-top: -3px;
	width: calc(100% - 40px);
	float: left;
	margin-left: 10px;
}

.auth_form__submit {
	width: calc(100% - -2px);
	height: 140px;
	text-align: center;
	color: #fff;
	font-size: 52px;
	color: #ffffff;
	position: absolute;
	bottom: -1px;
	left: -1px;
	cursor: pointer;
}

.auth_form__submit-restore {
	background: #e6a667;
	border-color: #d99d62;
}

.auth_form__submit-restore:hover {
	background: #f2b06d;
}

.auth_form__submit-reset {
	background: #88a3d6;
	border-color: #839ccc;
}

.auth_form__submit-reset:hover {
	background: #91aee3;
}

.restore_link {
	color: #00e3bb;
	display: inline-block;
	font-size: 12px;
	margin-bottom: 5px;
	margin-left: 1px;
	margin-left: 37px;
	text-indent: -37px;
}

.restore_link:hover {
	border-bottom: none;
}

.restore_link__text {
	display: inline !important;
}


.modal-auth-side {
	width: 687px;
	min-height: 620px;
	top: 63px;
	left: auto;
	background: #1a1a1a;
	border-left: 1px solid #fff;
	overflow-y: auto;
}


.modal-auth-side .modal_close,
.modal-auth-side .modal_overlay {
	display: none;
}

.modal-auth-side .modal_window {
	margin-top: 0;
	padding-top: 77px;
	border: none;
}

.modal-auth-side .auth_form__submit {
	width: 274px;
	height: 100px;
	position: relative;
	margin-top: 35px;
	font-size: 48px;
}

.modal-auth__mail {
	display: none;
	font-size: 18px;
	position: absolute;
	right: 0;
	bottom: 55px;
	left: 0;
	text-align: center;
	color: #f1f1f1;
}

.modal-auth-side .modal-auth__mail {
	display: block;
}



.modal-notification {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: auto;
}

.notification {
	width: 320px;
	padding: 20px 40px 20px 15px;
	background: #1a1a1a;
	border: 1px solid #ff6565;
	color: #fff;
	position: relative;
	box-sizing: border-box;
}

.notification__success {
	border: 1px solid #fff;
}

.notification__close {
	font-size: 30px;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	color: #ff6565;
}

.notification__success .notification__close {
	color: #fff;
}

.notification__title {
	font-size: 22px;
	margin-bottom: 10px;
}

.notification__text {
	font-size: 16px;
}



.api_docs {
	background: #fff;
	padding: 40px 5% 100px;
}

.api_docs h2 {
	margin-top: 30px;
	margin-bottom: 20px;
}

.api_docs h1,
.api_docs h3 {
	margin-top: 20px;
	margin-bottom: 10px;
}

.api_docs p {
	margin: 0 0 10px;
}

.api_docs__title_descr {
	font-size: 20px;
}

.api_docs pre {
	border-color: #1a1a1a;
	background: none;
	border-radius: 0px;
}

.api_docs .nav-tabs li.active a {
	border-color: #1a1a1a;
	border-radius: 0px;
}

.api_docs .tab-content {
	margin-top: -1px;
}

.api_docs .api_main__btn {
	border: 4px solid #7000ff;
	margin-bottom: 40px;
}

.api_docs .api_main__btn:hover {
	color: #fff;
	background: #7000ff;
	cursor: pointer;
	border: 4px solid #7000ff;
}





/* SPINNER */

.spinner_wrap {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,.7);
	z-index: 100;
}

.spinner {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	z-index: 100;
	width: 70px;
	height: 70px;
}

.spinner .everypixel_cross {
	width: 70px;
	height: 70px;
	position: relative;
	display: inline-block;
}

.spinner .everypixel_cross span {
	width: 10px;
	height: 10px;
	background: #fff;
	display: block;
	position: absolute;
	opacity: 0;
}

.everypixel_cross .first {	
	top: 0; left: 0;
	-webkit-animation: first 5s infinite ease-in-out;
 	animation: first 5s infinite ease-in-out;
}

.everypixel_cross .second {	
	top: 0; right: 0;
	-webkit-animation: second 5s infinite ease-in-out;
 	animation: second 5s infinite ease-in-out;
}

.everypixel_cross .third {	
	top: 16px; left: 16px;
	-webkit-animation: third 5s infinite ease-in-out;
 	animation: third 5s infinite ease-in-out;
}

.everypixel_cross .fourth {	
	top: 16px; right: 16px;
	-webkit-animation: fourth 5s infinite ease-in-out;
 	animation: fourth 5s infinite ease-in-out;
}

.everypixel_cross .fifth {	
	top: 31px; right: 30px;
	-webkit-animation: fifth 5s infinite ease-in-out;
 	animation: fifth 5s infinite ease-in-out;
}

.everypixel_cross .sixth {	
	bottom: 0; left: 0;
	-webkit-animation: sixth 5s infinite ease-in-out;
 	animation: sixth 5s infinite ease-in-out;
}

.everypixel_cross .seventh {	
	bottom: 0; right: 0;
	-webkit-animation: seventh 5s infinite ease-in-out;
 	animation: seventh 5s infinite ease-in-out;
}

.everypixel_cross .eighth {	
	bottom: 16px; left: 16px;
	-webkit-animation: eighth 5s infinite ease-in-out;
 	animation: eighth 5s infinite ease-in-out;
}

.everypixel_cross .nineth {	
	bottom: 16px; right: 16px;
	-webkit-animation: nineth 5s infinite ease-in-out;
 	animation: nineth 5s infinite ease-in-out;
}

.everypixel_cross .inside {
	height: 70px;
	width: 70px;
	-webkit-animation: together 5s infinite ease-in-out;
 	animation: together 5s infinite ease-in-out;
}


@-webkit-keyframes first {
	0% {
	    /*opacity: 0;
		top: 0;*/
	  }
	15% {
		opacity: 1;
		top: 0;
	}

	20% {
		opacity: 1;
		top: 0;
	}

	40% {
		top: calc(100% - 10px);
		left: 0;
	}

	50% {
		left: calc(100% - 10px);;
	}

	100% {
		opacity: 1;
		top: calc(100% - 10px);
		left: calc(100% - 10px);;
		
	}
}

@-webkit-keyframes second {
	0% {
	    /*opacity: 0;
		top: 0;*/
	  }
	20% {
		opacity: 1;
		top: 0;
	}

	40% {
		top: calc(100% - 10px);
		right: 0;
	}

	50% {
		right: calc(100% - 10px);
	}

	100% {
		top: calc(100% - 10px);
		right: calc(100% - 10px);
		opacity: 1;
	}
}

@-webkit-keyframes fifth {
	0% {
	    opacity: 0;

	  }
	5% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes sixth {
	0% {
	    /*opacity: 0;
	    bottom: 0;
	    left: 0;*/
	  }
	10% {
		opacity: 1;
	}

	25% {
		bottom: 0;
	}

	40% {
		bottom: calc(100% - 10px);
		left: 0;
	}

	50% {
		left: calc(100% - 10px);
	}

	100% {
		bottom: calc(100% - 10px);
		left: calc(100% - 10px);
		opacity: 1;
	}
}

@-webkit-keyframes seventh {
	0% {
	   /* opacity: 0;
	    bottom: 0;
	    right: 0;*/
	  }
	25% {
		opacity: 1;
		bottom: 0;
	}

	40% {
		bottom: calc(100% - 10px);
		right: 0;
	}

	50% {
		right: calc(100% - 10px);
	}

	100% {
		right: calc(100% - 10px);
		bottom: calc(100% - 10px);
		opacity: 1;
	}
}

/********************************/

@-webkit-keyframes third {
	0% {
	    opacity: 0;
	  }
	60% {
		opacity: 0;
	}
	70% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}


@-webkit-keyframes fourth {
	0% {
	    opacity: 0;
	  }
	60% {
		opacity: 0;
	}
	70% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}


@-webkit-keyframes eighth {
	0% {
	    opacity: 0;
	  }
	60% {
		opacity: 0;
	}
	70% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}


@-webkit-keyframes nineth {
	0% {
	    opacity: 0;
	  }
	60% {
		opacity: 0;
	}
	70% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes together {
	80% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-360deg);
	}
}








/* EASTETHIC TEST */


.estest {
	padding-top: 50px;
	padding-bottom: 100px;
	color: #fff;
	font-size: 0;
	background: #1a1a1a;
}

.estest ~ footer,
.contactus ~ footer,
.modal-auth ~ footer,
.refine ~ footer {
	display: none;
}

.estest .auth_btns {
	display: block;
	width: auto;
	height: auto;
	margin: 4px 0 0 12px;
}

.estest .auth_btn {
	line-height: 1;
	width: auto;
	height: auto;
	padding: 0;
	margin-right: 8px;
	border: none;
	border-bottom: 1px dashed #00b08a;
	color: #fff;
	font-size: 18px;
	display: inline-block;
	margin-top: 0;
	margin-bottom: 0;
}

.estest .auth_btn:hover {
	border-bottom-color: #00e3bb;
}

.estest_clone_wrap {
	width: 1200px;
	position: absolute;
	bottom: -100%;
	opacity: 0;
}


.estest_beta {
	height: inherit;
	display: inline-block;
	padding: 0 5px;
	background-color: #1a1a1a;
}

.estest .socials {
	display: none;
}

.estest_main {
	width: 40%;
	display: inline-block;
	vertical-align: top;
}

.estest_action {
	position: relative;
}

.estest_figure {
	height: 550px;
	position: relative;
	overflow: hidden;
}

.estest_figure__bg {
	width: 100%;
	height: 100%;
}

.estest_preview_bg {
	display: none;
	background-size: cover;
	width: 100%;
	height: 100%;
	opacity: .5;
	-webkit-filter: blur(10px);
	filter: blur(10px);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.estest_preview {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	max-width: 100%;
	max-height: 100%;
}

.estest_upload {
	position: absolute;
	top: 25px;
	right: 25px;
	bottom: 25px;
	left: 25px;
	border: 1px dashed #fff;
}

.estest_upload__btn {
	width: 85px;
	height: 85px;
	text-align: center;
	line-height: 87px;
	font-size: 28px;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 44px;
	left: 0;
	margin: auto;
	z-index: 1;
}

.estest_upload__file_input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}

.estest_upload__text {
	font-size: 24px;
	position: absolute;
	bottom: 101px;
	text-align: center;
	width: 100%;
}

.estest_upload__input_wrap {
	width: 245px;
	height: 38px;
	position: absolute;
	right: 0;
	bottom: 66px;
	left: 0;
	margin: auto;
	background: #000;
}

.estest_upload__input_wrap::after {
	content: '';
	display: block;
	height: 1px;
	position: absolute;
	right: 0;
	bottom: 8px;
	left: 0;
	background: #6a6a6a;
}

.estest_upload__input {
	width: 100%;
	height: 28px;
	box-sizing: border-box;
	padding: 0 31px 0 5px;
	background: #000;
	font-size: 24px;
	border: none;
}

.helper::before {
	content: '?';
	font-size: 11px;
}

.helper-estest {
	position: absolute;
	top: 2px;
	right: 3px;
	width: 22px;
	height: 22px;
	line-height: 24px;
	border-radius: 100%;
	border: 1px solid #0eb;
	color: #fff;
	text-align: center;
}

.helper-estest::before {
	font-size: 14px;
}

.helper__text {
	min-height: 50px;
	display: none; 
	color: #fff;
	font-size: 14px;
	line-height: 16px;
	background: #262626;
	position: absolute;
	top: -12px;
	left: 29px;
	min-width: 200px;
	text-align: left;
	padding: 9px 10px;
	box-sizing: border-box;
	z-index: 5;
}

.helper__text:before {
	content: " ";
	display: block;
	position: absolute;
	border-style: solid;
	border-width: 24px 10px 26px 0;
	border-color: transparent #262626 transparent transparent;
	top: 0;
	left: -10px;
}


.helper-estest .helper__text {
	left: 34px;
}

.helper:hover .helper__text {
	display: block;
	
}



.desktop_version .estest .footer__contact {
	float: none;
	margin-top: 21px;
}

.title-aux {
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 14px;
}

.estest_socials {
	width: 470px;
}

.share_btns {
	font-size: 0;
}

.estest_socials .share_btns {
	float: left;
	margin-right: 20px;
}

.share-dynamic {
	display: none;
}

.estest_socials__title {

}

.estest_socials__title-subst {
	display: none;
}

.estest_follow {
	display: inline-block;
}


.estest_info {
	width: 60%;
	max-width: 820px;
	display: inline-block;
	padding-left: 6%;
	box-sizing: border-box;
}

.estest_info__title {
	max-width: 660px;
	font-size: 64px;
	color: #fff;
	line-height: 1.111;
	margin-top: 30px;
}

.estest_info__title_text {
	box-shadow: 0 -0.26em #1a1a1a inset, 0 -0.35em #7000ff inset;
	text-shadow: -5px 2px #1a1a1a, 5px 2px #1a1a1a, -4px -2px #1a1a1a, 0 -3px #1a1a1a, -6px 2px #1a1a1a;
}

.estest_info__text {
	max-width: 660px;
	margin-top: 40px;
	font-size: 24px;
	color: #fff;
}

.estest__samples {
	margin-top: 35px;
}

.estest__samples__title {
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 14px;
}

.estest__samples__list {
	display: flex;
	margin-top: 10px;
	white-space: nowrap;
}

.estest__samples__item {
	height: 96px;
	display: inline-block;
	cursor: pointer;
	margin-right: 12px;
	position: relative;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.estest__samples__item-video {
	height: 74px;
}

.estest__samples__img {
	max-height: 100%;
	max-width: 100%;
}

.estest__samples__item:hover .estest__samples__img {
    transform: scale3d(1.09,1.09,1);
    transition: opacity .35s,transform .35s;    
    opacity: .9;
}

.estest_info__tip {
	margin-top: 10px;
	display: none;
	font-size: 14px;
	line-height: 16px;
}


.estest_result {
	padding-top: 35px;
}

.estest_result__score {
	font-size: 144px;
	font-family: 'CirceWebLight', sans-serif;
	line-height: .87;
}

.estest_result__descr {
	font-size: 24px;
	line-height: 1.3;
	position: relative;
}

.estest_info__share {
	display: none;
	line-height: 1.6;
	vertical-align: top;
	margin: 33px 0 0 20px;
}

.estest_info__share .share_btns {
	margin-top: 15px;
}


.estest_suggest__title {
	font-size: 24px;
}

.estest_suggest__list_wrap {
	width: 100%;
	height: 340px;
	overflow: auto;
	border: 1px solid #fff;
}

.estest_suggest__list {
	color: #fafafa;
	font-size: 16px;
	list-style-type: none;
}

.estest_suggest__item {
	border-bottom: 1px solid #fafafa;
	overflow: hidden;
}

.estest_suggest__item:last-child {
	border-bottom: none;
}

.estest_suggest__tag {
	padding-left: 10px;
	float: left;
}

.estest_suggest__score {
	padding-right: 10px;
	float: right;
}

.estest_result__btn {
	line-height: 93px;
	font-size: 32px;
	margin-top: -1px;
	display: block;
	position: relative;
}

.estest_result__btn:hover {
	color: #fff;
}

.estest_result-scoring .estest_result__btn,
.estest_result-attraction .estest_result__btn {
	margin-top: 60px;
	margin-bottom: 10px;
}

.desktop_version .estest_result__btn {
	max-width: 320px;
}

.desktop_version .estest_suggest__list_wrap,
.desktop_version .estest_result-tagging .estest_result__btn {
	max-width: 550px;
}

.estest_suggest__tip {
	margin-top: 10px;
	font-size: 14px;
}

.modal-estest {
	display: none;
	top: auto;
	left: 50%;
	bottom: -1px;
	overflow-y: auto;
	margin-left: -90px;
}

.modal-estest .modal_overlay {
	background: none;
}

.modal-estest .modal_window {
	width: 100%;
	max-width: 782px;
	margin: 0;
	border: 1px solid #fff;
	padding: 35px 130px 45px 30px;
	background: #1a1a1a;
	font-size: 24px;
	box-sizing: border-box;
}

.estest_share_title {
	display: none;
	margin-bottom: 10px;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.estest_results .footer-estest .footer__content {
	padding: 0;
	width: auto;
}

.estest_results .footer-estest .footer__content .footer__fasie {
	left: 0;
}



.estest_results .estest {
	padding-top: 40px;
}

.estest_results .estest_figure__bg,
.estest_results .estest_info__title,
.estest_results .estest_info__text,
.estest_results .estest_upload__helper,
/* .estest_results .footer-estest .footer__contact, */
.estest_results .estest_socials .share_btns,
.estest_results .share-static,
.estest_results .estest_socials__title {
	display: none !important;
}

.estest_results .estest_preview_bg,
.estest_results .estest_preview,
.estest_results .estest_suggest {
	display: block;
}



.estest_results.desktop_version .estest_upload {
	height: 83px;
	margin-top: 30px;
	margin-left: 88px;
	padding: 20px 0 0 20px;
	border-width: 1px 1px 1px 0;
	position: relative;
	top: 0;
	left: 0;
}

.estest_results.desktop_version .estest_upload__btn {
	top: -1px;
	right: auto;
	left: -89px;
	bottom: auto;
}

.estest_results .estest_upload__btn:hover {
	border-color: #1a1a1a;
}

.estest_results.desktop_version .estest_upload__text {
	width: auto;
	font-size: 18px;
	margin-left: 5px;
	position: static;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
}

.estest_results .estest_upload__input_wrap {
	width: 81px;
	height: auto;
	margin-left: 5px;
	background: #1a1a1a;
	position: static;
	display: inline-block;
}

.estest_results .estest_upload__input_wrap::after {
	content: none;
}

.estest_results .estest_upload__input {
	font-size: 18px;
	background: #1a1a1a;
	padding: 0;
	border-bottom: 1px solid #a8a8a9;
}

.estest_upload__formats {
	font-size: 12px;
	color: #888888;
	position: absolute;
	bottom: 15px;
	right: 0;
	left: 0;
	text-align: center;
}

.estest_results .estest_upload__formats {
	left: 25px;
	text-align: left;
}

.estest_upload__formats.go {
	animation-name: wrong_size;
	animation-duration: 1200ms;
	animation-timing-function: linear;
}

@keyframes wrong_size {
	from {
		transform: translateX(0);
	}
	10% {
		transform: translateX(-10px);
		color: #fff;
	}
	20% {
		transform: translateX(10px);
	}
	30% {
		transform: translateX(-10px);
	}
	40% {
		transform: translateX(10px);
	}
	50% {
		transform: translateX(-10px);
	}
	60% {
		transform: translateX(10px);
	}
	70% {
		transform: translateX(-10px);
	}
	80% {
		transform: translateX(10px);
		color: #fff;
	}
	90% {
		transform: translateX(-10px);
	}
	to {
		transform: translateX(0);
	}
}

.estest_results .footer-estest {
	position: static;
	margin-top: 80px;
}



.body_aesthetics_test .dnd_holder {
	opacity: 0;
}

.estest_results .dnd_holder {
	opacity: 1;
}




.waiting::after {
	content: '';
	background: rgba(0,0,0,.7);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	z-index: 9;
}


.desktop_version:not(.estest_results) .estest_upload .spinner {
	bottom: 29px;
}

.estest_results.desktop_version .estest_upload .spinner {
	transform: scale(.6);
}

.estest__samples__item .spinner {
	transform: scale(.6);
}

.share_btn .spinner,
.mobile_share__link .spinner {
	left: -14px;
	transform: scale(.5);
}

.escaptcha_block {
	margin-top: 15px;
	width: 40%;
}

.escaptcha_form {
	width: 40%;
	display: inline-flex;
}

.escaptcha_submit {
	font-size: 1.6vw;
	/* captcha is active 
	margin-left: 1vw; */
	margin-left: 13vw;
	justify-content: center;
	line-height: 3.8vw;
	height: 3.8vw;
	width: 30%;
	display: inline-flex;
	background: #1a1a1a;
	color: #fff;
	border: 2px solid #fff;
}


.estest_type_btns {
	
}

.estest_type_btn {
	position: relative;
	padding: 0 4px;
	width: 20%;
	height: 45px;
	white-space: pre-wrap;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
}

.estest_type_btn.active {
	border-color: #7000ff;
	z-index: 2;
}

.estest_type_btn:hover {
	color: #fff;
}

.estest_type_btn + .estest_type_btn {
	margin-left: -1px;
}

.estest_type_btn  .spinner {
	transform: scale(.5);
}




/* CONTACT US */

.contactus {
	background: #1a1a1a;
	min-height: calc(100vh - 93px);
	font-size: 0;
	padding-bottom: 120px;
}

.contactus_content {
	width: 60%;
	padding-top: 5vw;
	padding-right: 10%;
	display: inline-block;
	vertical-align: top;
	color: #fff;
	box-sizing: border-box;
}

.contactus_content__text {
	font-size: 36px;
	padding-bottom: 4.5vw;
}

.contactus_content__more {
	font-size: 24px;
	margin-top: 15px;
	width: 89%;
}

.contactus_form {
	display: inline-block;
	width: 40%;
	margin-top: 2vw;
	position: relative;
	font-size: 14px;
}

.contactus_form textarea {
	height: 100px;
	resize: none;
	display: block;
}


.contactus_form .auth_form__submit {
	width: calc(100% - -1px);
	height: 120px;
	position: relative;
	margin-top: -1px;
	left: 0;
}

.contactus_form .auth_form__captcha {
	margin: 20px auto 0;
	width: 302px;
}


/* CONFIRM PAGE */

.account_confirm_wrap {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.account_confirm {
	width: 625px;
	margin-bottom: auto;
}

.account_confirm_title {
	font-size: 36px;
	line-height: 40px;
}

.confrim_inputs_group {
	margin-top: 23px;
	width: 558px;
}

.confrim_inputs_group:first-of-type {
	margin-top: 15px;
}

.confrim_group_title {
	margin-bottom: 17px;
	font-size: 18px;
}

.confirm_error {
	font-size: 18px;
	color: #FF6565;
	margin-top: 40px;
	display: none;
}

.confirm_error.opened {
	display: block;
}

.confirm_user_input {
	border: none;
	border-radius: 0;
	box-shadow: 
 		1px 0 0 0 #000, 
  	0 1px 0 0 #000, 
  	1px 1px 0 0 #000,
 	 	1px 0 0 0 #000 inset, 
  	0 1px 0 0 #000 inset;
  font-size: 14px;
  padding-top: 25px;
  padding-bottom: 25px;
	transition: all .3s ease;
}

.confirm_user_input.error {
	box-shadow: 
 		1px 0 0 0 #FF6565, 
  	0 1px 0 0 #FF6565, 
  	1px 1px 0 0 #FF6565,
 	 	1px 0 0 0 #FF6565 inset, 
  	0 1px 0 0 #FF6565 inset;
}

.confirm_user_input:focus {
	box-shadow: 
 		1px 0 0 0 #0099CC, 
  	0 1px 0 0 #0099CC, 
  	1px 1px 0 0 #0099CC,
 	 	1px 0 0 0 #0099CC inset, 
  	0 1px 0 0 #0099CC inset;
  outline: none;
	border: none;
}

.confirm_job_unput  {
	display: inline-block;
	width: 100%;
	float: left;
	height: 50px;
	padding-top: 0;
	padding-bottom: 0;
}

.confirm_job_unput option {
	border: 1px solid black;
}

.confirm_other_job_unput {
	visibility: hidden;
	height: 0;
	padding: 0;
	opacity: 0;
	transition: opacity .3s ease-in-out;
}

.confirm_other_job_unput.opened {
	margin-top: 8px;
	visibility: visible;
	width: 100%;
	height: 100px;
	padding: inherit;
	opacity: 1;
	padding-top: 15px;
  padding-bottom: 25px;
  padding-left: 10px;
}

.job_title_btn {
	display: inline-block;
	width: 19%;
	float: right;
	height: 50px;
	background: #1F1F1F;
	color: #fff;
	font-size: 18px;
}

.float_cleaner {
	clear: both;
}

.confirm_send_btn {
	width: 558px;
	background: #1f1f1f;
	color: #fff;
	font-size: 37px;
	margin-top: 40px;
	padding: 20px 0;
}

.job_title_btn:hover ,
.confirm_send_btn:hover {
	background: #fff;
	color: #7000ff;
	box-shadow: 0 0 0 3px #7000ff;
}

.account_confirm_info {
	padding: 53px 40px;
	box-shadow: 0 0 0 1px #700fff;
	width: 352px;
}

.confirm_info_number {
	font-size: 94px;
	line-height: 1;
}

.confirm_info_title {
	font-size: 36px;
	margin-bottom: 20px;
	line-height: 40px;
}

.confirm_info_line {
	margin-bottom: 20px;
}

.confirm_info_img {
	margin-bottom: 5px;
}

.confirm_info_text {
	font-size: 18px;
}


.account_left_menu {
	width: 240px;
	box-shadow: 0 0 0 1px #E0E0E0;
	display: inline-block;
	border-radius: 3px;
}

.account_menu_info {
	text-align: center;
	padding-top: 25px;
}

.account_menu_img {
	height: 36px;
	margin-bottom: 6px;
}

.account_menu_name {
	font-size: 20px;
	margin-bottom: 2px;
}

.account_menu_role {
	font-size: 14px;
	margin-bottom: 6px;
	color: #A2A2A2;
}

.account_menu_item {
	list-style: none;
	font-size: 14px;
	padding: 21px 22px;
	font-weight: bold;
	transition: all .1s ease-in-out;
}

.account_menu_item a {
	color: #1f1f1f;
	text-decoration: none;
	width: 100%;
	display: block;
}

.account_menu_item:not(.active):hover {
	cursor: pointer;
}

.account_menu_item:not(.active):hover a {
	color: #7000ff;
}

.account_menu_item.active {
	background: #FFF6F7;
	box-shadow: 0 0 0 1px #E0E0E0;
	color: #7000FF;
	border-left: 3px solid #7000ff;
}

.account_credentials_column {
	max-width: max-content;
	display: inline-block;
	float: left;
	margin-right: 20px;
}

.mb-0 {
	margin-bottom: 0px;
}

.mt-10 {
	margin-top: 10px;
}

.ml-10 {
	margin-left: 10px;
}

.mr-10 {
	margin-right: 10px;
}

.mb-10 {
	margin-bottom: 10px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flex-row.hide {
    display: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 100px;
  padding: 10px 10px;
  z-index: 3;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 0;
}

.tooltip-cstm {
  position: relative;
}

.tooltip-cstm .tooltiptext-cstm {
  visibility: hidden;
  background-color: #1a1a1a;
  font-size: 14px;
  max-width: 250px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 5px;
  white-space: initial;
  position: absolute;
  z-index: 1;
  left: 110%;
}

.tooltip-cstm:hover .tooltiptext-cstm {
  visibility: visible;
}

.menu-icon-wrapper {
    margin-top: auto;
    margin-bottom: auto;
}

.menu-icon {
    margin-left: 20px;
}

.menu-icon:hover {
    cursor: pointer;
}

.menu-icon a {
    padding-right: 0 !important;
    margin-left: 20px;
}

.playground_projects {
	
}

.playground_projects_empty {
	padding-top: 45px;
	text-align: center;
	display: none;
}

.playground_projects_empty div {
	font-size: 18px;
	color: #A8A8A9;
}

.playground_projects_empty .account_btn {
	margin-top: 18px;
	display: inline-flex;
	padding-top: 12px;
	padding-bottom: 12px;
}

.profile_content_block.empty .playground_projects_empty {
	display: block;
}

.profile_content_block.empty .new_project_btn {
	display: none;
}

.gosync-project {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.gosync-project .account_btn {
	border-width: 1px;
	padding-top: 6px;
	padding-bottom: 6px;
	font-size: 14px;
}

.gosync-project .account_btn:hover {
	border-width: 1px;
}

.gosync-card {
    position: relative;
	transition: none !important;
    border: 1px solid black;
	border-radius: 0;
}

.gosync-card:hover {
    /* outline: 2px solid #7000ff; */
    border: 1px solid #7000ff;
}

.gosync-element {
	margin: 0 0 16px;
	padding: 12px 10px;
}

.gosync-project {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.666);
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.2s ease 0s;
    z-index: 4;
}

.popup:target {
    opacity: 1;
    visibility: visible;
}

.popup__body {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 10px;
}

.popup__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #000;
    max-width: 800px;
    padding: 30px;
}

.clickable-card {
    display: block;
    z-index: 1;
}

.clickable-card::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: ' ';
}

.tts-card {
    display: flex;
    flex-direction: row;
}

.tts-card-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.tts-card .counter {
	position: absolute;
	font-size: 12px;
	transform: translate(2px, -18px);
	background: #fff;
	padding: 0 3px;
	border-radius: 4px;
	display: flex;
	gap: 5px;
}

.tts-card-header {
    font-size: 20px;
}

.tts-project-header {
    font-size: 20px;
    margin-bottom: auto;
}

.tts-status {
    text-transform: capitalize;
    font-weight: 700;
    text-align: center;
    font-size: 12px;
    cursor: default;
}

.tts-dropdown a:hover {
    color: #7000ff !important;
}

.tts-dropdown-item:hover {
    color: #7000ff !important;
}

.send-form-tts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
	position: relative;
}

.send-form-tts .counter {
	position: absolute;
	bottom: 53px;
	margin-left: 2px;
	font-size: 12px;
	background: #fff;
	padding: 0 3px;
	border-radius: 4px;
	display: flex;
	gap: 5px;
}

.send-form-tts textarea {
	min-height: 174px;
}

.send-form-tts-columns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.send-form-tts select {
	text-transform: capitalize;
}

.video-body{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

.video-card {
	width: calc(100% / 2 - 5px);
}

.video-card-body {
    display: flex;
    flex-direction: column;
    height: 225px;
}

.video-card-preview-body {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 10px;
}

.video-card-title {
	position: relative;
}

.file_type_helper {
	display: inline-block;
	transform: translate(5px, -3px);
	vertical-align: middle;
}

.file_type_helper .icon {
	width: 15px;
	height: 15px;
	background-image: url('../i/icon-info.webp');
	background-size: contain;
	cursor: pointer;
}

.file_type_helper .info {
	background-color: #fff;
	display: none;
	border: 1px solid #000;
	padding: 3px 5px;
	font-size: 12px;
	position: absolute;
	white-space: nowrap;
	margin-left: -90px;
}

.file_type_helper:hover .info {
	display: block;
}

.tts-card-preview-body {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.tts-card-preview-bottom {
	display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.guide {
    font-size: 16px;
}
.guide-params {
    width: fit-content;
    font-size: 14px;
}
.guide-params td {
    text-align: left;
}
.guide-params td:nth-child(odd) {
    text-align: left;
    padding-right: 5px;
}
.guide-strong {
    font-weight: 700;
}
.guide-reqs {
    padding: 15px 10px 15px 10px;
    font-weight: 700;
    line-height: 2;
}
.guide-reqs img {
    width: 200px;
}
.guide-shooting img {
    margin-right: 10px;
}

@media screen and (max-width: 960px) {
	.video-card {
		width: 100%;
	}
}

.bg-complete {
	background-color: #28a745!important;
}

.bg-started {
	background-color: #007bff!important;
}

.bg-failed {
	background-color: #dc3545!important;
}

.bg-pending {
	background-color: #17a2b8!important;
}

.text-white {
	color: #fff!important;
}

.text-dark {
	color: #343a40!important;
}

.panel-body__title {
	margin-bottom: 5px;
}

.secret_btn,
.save_info,
.add_emails_btn,
.change_pass {
	margin-top: 10px;
	border: none;
	box-shadow: 0 0 0 1px #000;
	border-radius: 3px;
	font-size: 14px;
}

.save_info,
.change_pass {
	width: 139px;
}

.profile_index_presonal {
	width: 558px;
}

.profile_index_emails {
	width: 266px;
}

.user_email_title,
.add_email_title {
	font-size: 14px;
	font-weight: bold;
}

.add_email_title {
	margin-top: 10px;
}

.add_email_input {
	margin-top: 10px;
}

.email_group {
	background: #F5F5F5;
  margin-top: 5px;
  border: 1px solid #DFDFDF;
  padding: 8px 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info_row {
	margin-bottom: 10px;
}

.panel-body {
	padding: 26px 15px;
}

.panel-body p {
	padding: 15px;
	margin: 0 0 10px;
	position: relative;
}

.profile_index_panels_group {
	display: flex;
	justify-content: space-between;
}

.profile_balance_wrap,
.profile_statistics_wrap {
	float: right;
  width: 850px;
}

.profile_balance,
.profile_statistics {
	margin: 0 0;
}


.profile_thanks_text {
	font-size: 20px;
	margin-bottom: 20px;
	margin-top: 10px;
}

.profile_index_guide {
	margin-top: 40px;
}

.profile_guide_row {
	margin-top: 20px;
}

.profile_upload_input {
	margin-bottom: 20px;
	margin-top: 10px;
}

.guide_row_title {
	font-size: 20px;
}

.guide_row_title a {
	color: #7000FF;
	text-decoration: none;
	transition: all .3s ease-in-out;
}

.guide_row_title a:hover {
	cursor: pointer;
	animation: recolor_link 2s infinite;
}

.guide_row_text {
	font-size: 20px;
	margin-top: 5px;
}

@keyframes recolor_link {
	50% {
		color: #000;
	}
	100% {
	 color: #7000FF;
	}
}



/* REFINE LANDING */

.refine {
	padding-top: 50px;
	padding-bottom: 100px;
	color: #fff;
	background: #1a1a1a;
}

.refine_main {
	display: flex;
	gap: 5%;
}

.refine_figure {
	width: 40vw;
	height: 40vw;
	display: flex;
	align-items: center;
}

.refine_beforeafter {
	opacity: 0;
	transition: opacity .4s;
	display: none;
}

.refine_figure.inited .refine_beforeafter {
	opacity: 1;
} 

.refine_beforeafter.active {
	display: block;
}

.refine_info {
	max-width: 610px;
	display: flex;
    flex: 1 1 0;
}

.refine_info__inner {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
}

.refine_title {
	font-size: 64px;
    line-height: 1.111;
}

.refine_descr {
	font-size: 21px;
	margin-top: 50px;
}

.refine_try {
	display: flex;
	gap: 20px;
	align-items: center;
}

.refine_try__btn {
	font-size: 24px;
	padding: 10px 20px;
	border: 1px solid #fff;
	color: #fff;
	white-space: nowrap;
}

.refine_try__btn:hover {
	border-color: #7000ff;
	box-shadow: 0 0 0 5px #7000ff;
}

.refine_try__descr {
	font-size: 21px;
}


.refine_second {
	margin-top: 80px;
	display: flex;
	gap: 5%;
}

.refine_samples {

}

.refine_samples__title {
	font-size: 24px;
	margin-bottom: 10px;
}

.refine_samples__list {
	width: 40vw;
	display: flex;
	justify-content: space-between;
	gap: 20px
}

.refine_samples__item {
	list-style-type: none;
	cursor: pointer;
}

.refine_samples__item img {
	max-width: 100%;
}

.refine_pricing {
	max-width: 610px;
}

.refine_pricing__title {
	font-size: 24px;
	margin-bottom: 10px;
}

.refine_pricing__items {
	display: flex;
	justify-content: space-between;
	gap: 5%;
}

.refine_pricing__item {
	list-style-type: none;

}

.refine_pricing__item__title {
	font-size: 22px;
}

.refine_pricing__item__text {
	font-size: 18px;
}




/* LIPSYNC LANDING */

.lipsync-block.epcontainer,
.lipsync-block .epcontainer {
	padding-left: 30px;
    padding-right: 30px;
}

.lipsync-block .features_items {
	width: 100%;
	margin-bottom: 9px;

	display: flex;
	align-items: flex-start;
	gap: 40px;
}

.lipsync-block .features_item {
	flex-basis: calc((100% - 80px) / 3);
}

.lipsync-block .features_item .press_item__title {
	width: 100%;
	padding: 40px 0 10px;
	min-height: 205px;
	margin-bottom: 32px;
}

.lipsync-block .features_item .press_item__text {
	width: 100%;
	max-width: 100%;
}

.lipsync-block .api_main__decsr {
	max-width: 935px;
}

.lipsync-block.api_price {
	list-style-type: none;
	padding-bottom: 40px;
	padding-top: 50px;
	margin-top: 0;
	margin-bottom: 0;
	overflow: hidden;
}

.lipsync-block .pricing_press_text {
	margin-top: 14px;
	font-size: 28px;
}

.lipsync-block .api_price__item {
	width: 33.33%;
	min-height: 477px;
}

.lipsync-block .api_price__item__name {
	font-size: 36px;
}

.lipsync-block .api_price__item__descr {
	font-size: 20px;
}

.lipsync-block .api_price__item__info {
	font-size: 28px;
	bottom: 15px;
}

.lipsync-block .list_block {
	display: flex;
	width: 100%;
	gap: 2.7vw;
	margin-bottom: 73px;
	flex-wrap: wrap;
}

.lipsync-block .list_item {
	flex-basis: calc(50% - (2.7vw / 2));
}
  
.lipsync-block .list_item .press_item__title {
	margin-bottom: 25px;
	max-width: 100%;
}
  
.lipsync-block .list_item .press_item__text {
	max-width: 100%;
	height: 19.6vw;
	min-height: 255px;
	padding: 2.37vw 1.55vw 1.29vw 2.64vw;
	border: 2px solid #7000FF;
	box-sizing: border-box;
}

.lipsync_block2 {
	display: flex;
	gap: 40px;
	align-items: flex-start;
}

.features_block.lipsync-block {
	background-color: #ffffff;
}

.api_price.lipsync-block {
	background-color: #ffffff;
}

.cases-block.lipsync-block {
	background-color: #ffffff;
}

.lipsync_block2 .press_title {
	max-width: 53vw;
}

.lipsync_video {
	width: 34%;
}

.about_text-list {
	list-style-position: inside;
}

.lipsync_img {
	width: 90%;
	margin-top: 2vw;
}

.lipsync_items {
	padding-bottom: 60px;
	display: flex;
	gap: 2vw;
}

.lipsync_item {
	width: 29%;
}

.lipsync_items .press_item__title {
	min-height: auto;
}

@media screen and (min-width: 1472px) {
	.lipsync-block .api_main__decsr {
		max-width: 63.5vw;
	}

	.lipsync-block .features_item .press_item__title {
		width: 100%;
		max-height: 18.54vw;
		padding: 0;
		font-size: 2.44vw;
		min-height: 12vw;
	
		margin-bottom: 1.15vw;
	}
	
	.lipsync-block .features_item .press_item__text {
		width: 100%;
		font-size: 1.63vw;
	}
	
	.lipsync-block.features_block .press_title {
		font-size: 3.26vw;
		margin-bottom: 2.71vw;
	}

	.lipsync-block .pricing_press_text {
		margin-top: 14px;
		font-size: 1.9vw;
	}

	.lipsync-block .api_price__item {
		width: 33.33%;
		min-height: 32.98vw;
	}

	.lipsync-block .api_price__item__name {
		font-size: 2.48vw;
	}
	
	.lipsync-block .api_price__item__descr {
		font-size: 1.38vw;
	}
	
	.lipsync-block.api_price__item__info {
		font-size: 1.93vw;
	}

	.lipsync-block .list_block {
		gap: 5.4vw;
		margin-bottom: 73px;
	}

	.lipsync-block .list_item {
		flex-basis: calc(50% - (5.4vw / 2));
	}

	.lipsync-block .list_item .press_item__title {
		font-size: 2.44vw;
	}
	  
	.lipsync-block .list_item .press_item__text {
		font-size: 1.825vw;
		min-height: 17.32vw;
		height: 19.6vw;
		padding: 1.96vw 1.56vw 1.29vw 2.64vw;
	}
}

/* TEXT-TO-SPEECH LANDING */

.ttsl-block .features_items {
	width: 100%;
	margin-bottom: 9px;

	display: flex;
	align-items: flex-start;
	gap: 40px;
}
  
.ttsl-block .features_item {
	width: 22.5%;
}
  
.ttsl-block .features_item .press_item__title {
	width: 100%;
	padding: 40px 0 10px;
	min-height: 130px;
	margin-bottom: 32px;
}
  
.ttsl-block .features_item .press_item__text {
	width: 100%;
	max-width: 100%;
}

.ttsl-block.api_price {
	list-style-type: none;
	padding-bottom: 40px;
	padding-top: 50px;
	margin-top: 0;
	margin-bottom: 0;
	overflow: hidden;
}
  
.ttsl-block .pricing_press_text {
	margin-top: 14px;
	font-size: 28px;
}
  
.ttsl-block .api_price__item {
	width: 33.33%;
	min-height: 477px;
}
  
.ttsl-block .api_price__item__name {
	font-size: 36px;
}
  
.ttsl-block .api_price__item__descr {
	font-size: 20px;
}
  
.ttsl-block .api_price__item__info {
	font-size: 28px;
	bottom: 15px;
}

.features_block.ttsl-block {
	background-color: #ffffff;
}

.api_price.ttsl-block {
	background-color: #ffffff;
}

.cases-block.ttsl-block {
	background-color: #ffffff;
}

.ttsl_block {
	padding-bottom: 60px;
}

.ttsl_block4 {
	background: #fff;
	margin-top: 0;
}

.ttsl_block4_prices {
	display: flex;
	margin-top: 40px;
}

.ttsl_block4_prices .api_price__item {
	width: 31%;
}

.ttsl_block1 .press_title {
	width: 100%;
}

.ttsl_block1 .about_text {
	width: 100%;
}

.ttsl_block1 {
	display: flex;
	gap: 40px;
}

.ttsl_form {
	width: 34%;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.ttsl_text_block {
	flex-basis: calc(100% - 34% - 40px);
}

.ttsl_form_textarea {
	width: 100%;
	padding: 5px 10px;
	box-sizing: border-box;
	height: 120px;
	resize: vertical;
	font-size: 18px;
}

.ttsl_form__select_wrapper {
	display: flex;
	flex-flow: row wrap;
	height: auto;
	gap: 2%;
}

.ttsl_form_select {
	flex-basis: calc(50% - (2% / 2));
	margin-bottom: 2%;
}

.ttsl_form_select label {
	font-size: 18px;
}

.ttsl_form_select.hidden {
	display: none;
}

.ttsl_form_submit {
	height: 50px;
	max-width: 200px;
	border: 1px solid #000;
	font-size: 20px;
	position: relative;
}

.ttsl_error {
	font-size: 18px;
	color: #f00;
	position: absolute;
	bottom: 30px;
	display: none;
}

.hidden {
	display: none;
}

.ttsl_audio[src=""] {
	display: none;
}

.ttsl_form.loading .ttsl_form_submit {
	font-size: 0;
	pointer-events: none;
}

.ttsl_form.loading .ttsl_form_submit::after {
	content: '';
	width: 24px;
	height: 24px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><script xmlns="" async="false" type="text/javascript" src="chrome-extension://fnjhmkhhmkbjkkabndcnnogagogbneec/in-page.js"/> <line x1="12" y1="2" x2="12" y2="6"/> <line x1="12" y1="18" x2="12" y2="22"/> <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"/> <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"/> <line x1="2" y1="12" x2="6" y2="12"/> <line x1="18" y1="12" x2="22" y2="12"/> <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"/> <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"/> </svg>');
	animation: 2000ms linear 0s infinite normal none running rotate;
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.ttsl_block2 {
	padding-top: 40px;
	padding-bottom: 40px;
	background: #7000ff;
	display: flex;
	gap: 40px;
	justify-content: space-between;
	align-items: center;
}

.ttsl_block2_text {
	max-width: 500px;
	font-size: 20px;
	color: #fff;
}

.ttsl_block2_btn {
	width: 200px;
	height: 50px;
	font-size: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ttsl_items {
	display: flex;
	gap: 2vw;
}

.ttsl_item {
	width: 31%;
}

.ttsl_items .press_item__title {
	min-height: auto;
}

.ttsl_block3 {
	padding-top: 40px;
	background: #fff;
}

@media screen and (min-width: 1472px) {
	.ttsl-block .api_main__decsr {
		max-width: 63.5vw;
	}

	.ttsl-block .features_item .press_item__title {
		font-size: 2.48vw;
		width: 100%;
		max-height: 18.54vw;
		padding: 0;
		min-height: 12vw;
		margin-bottom: 1.15vw;
	}
	
	.ttsl-block .features_item .press_item__text {
		width: 100%;
		font-size: 1.63vw;
	}
	
	.ttsl-block.features_block .press_title {
		font-size: 3.26vw;
		margin-bottom: 2.71vw;
	}

	.ttsl-block .pricing_press_text {
		margin-top: 14px;
		font-size: 1.9vw;
	}

	.ttsl-block .api_price__item {
		width: 33.33%;
		min-height: 32.98vw;
	}

	.ttsl-block .api_price__item__name {
		font-size: 2.48vw;
	}
	
	.ttsl-block .api_price__item__descr {
		font-size: 1.38vw;
	}
	
	.ttsl-block.api_price__item__info {
		font-size: 1.93vw;
	}

	.ttsl-block .list_block {
		gap: 5.4vw;
		margin-bottom: 73px;
	}

	.ttsl-block .list_item {
		flex-basis: calc(50% - (5.4vw / 2));
	}

	.ttsl-block .list_item .press_item__title {
		font-size: 2.44vw;
	}
	  
	.ttsl-block .list_item .press_item__text {
		font-size: 1.825vw;
		min-height: 17.32vw;
		height: 19.6vw;
		padding: 1.96vw 1.56vw 1.29vw 2.64vw;
	}

}

/*KEYWORDING LANDING*/

.keywording-block ~ footer {
	display: block;
}

.keywording-block {
	background-color: #ffffff;
}

.keywording-block.api_main {
	background-color: inherit;
}

.keywording-block .api_main__decsr {
	max-width: 935px;
}

.info_page__link_items {
	display: flex;
	gap: 52px;
	margin-top: 91px;
	list-style: none;
}

.info_page__link_item {

}

.info_page__link {
	color: #FFF;
	font-size: 28px;
	line-height: normal;
	text-decoration-line: underline;
}

.keywording-block .about_block {
	display: flex;
	margin-bottom: 20px;
}

.keywording-block .about_block .text_block {
	width: 907px;
	margin-right: 74px;
}

.keywording-block .press_title {
	line-height: 71px;
	width: 721px;
}

.keywording-block .about_block .text_block .about_text {
	max-width: 907px;
	line-height: 41px;
	margin-top: 17px;
}

.border_block {
	width: 321px;
	height: 393px;
	border: 7px solid #7025FB;
	box-sizing: border-box;
}

.keywording_items {
	width: 100%;
	margin-bottom: 9px;

	display: flex;
	align-items: flex-start;
	gap: 40px;
}

.keywording_item {
	width: 100%;
}

.features_block.api_block {
	padding-top: 65px;
}

.keywording_item .press_item__title {
	width: 100%;
	max-height: 273px;
	padding: 40px 0 10px;
	min-height: 130px;
	margin-bottom: 32px;
}

.keywording_item .press_item__text {
	width: 100%;
}

.keywording-block .pricing_press_text {
	margin-top: 14px;
	font-size: 28px;
}

.keywording-block .more_press_text {
	margin-bottom: 16px;
	font-size: 28px;
}

.keywording-block.api_price {
	list-style-type: none;
	padding-bottom: 20px;
	padding-top: 30px;
	margin-top: 0;
	margin-bottom: 0;
	overflow: hidden;
}

.keywording_block4_prices {
	width: 100%;
	margin-top: 34px;
}

.keywording-block .api_price__item {
	width: 33.33%;
	min-height: 477px;
}

.keywording-block .api_price__item__name {
	font-size: 36px;
}

.keywording-block .api_price__item__descr {
	font-size: 20px;
}

.keywording-block .api_price__item__info {
	font-size: 28px;
	bottom: 15px;
}

.cases-block {
	display: flex;
	flex-direction: column;
}

.cases-block .press_title {
	margin-bottom: 20px;
}

.list_block {
	display: flex;
	width: 100%;
	gap: 4vw;
	margin-bottom: 73px;
}

.list_item {
	width: 50%;
}

.list_item .press_item__title {
	margin-bottom: 25px;
}

.list_item .press_item__text {
	max-width: 100%;
	min-height: 255px;
	padding: 35px 38px 19px 39px;
	border: 2px solid #7000FF;
	box-sizing: border-box;
}

.cases-block .press_text {
	margin-bottom: 28px;
	font-size: 48px;
}

.cases-block .btn {
	align-self: center;
}

.cases-block .btn-plain {
	border: 1px solid #1A1A1A;
}

.cases-block .btn-plain:hover {
	color: #1A1A1A;
}

/*estest block*/

.list_block {
	display: flex;
	width: 100%;
	gap: 2.7vw;
	margin-bottom: 73px;
	flex-wrap: wrap;
}

.list_item {
	flex-basis: calc(50% - (2.7vw / 2));
}

.list_item .press_item__title {
	margin-bottom: 25px;
	max-width: 100%;
}

.list_item .press_item__text {
	max-width: 100%;
	height: 19.6vw;
	min-height: 255px;
	padding: 2.37vw 1.55vw 1.29vw 2.64vw;
	border: 2px solid #7000FF;
	box-sizing: border-box;
}

.keywording-block.estest {
	color: #1a1a1a;
	padding-bottom: 0;
}

.keywording-block .estest_figure {
    height: 518px;
    overflow: hidden;
}

.keywording-block .estest_main {
    width: 35%;
}

.keywording-block .estest_info {
	max-width: unset;
}

.keywording-block .estest_upload__text {
	color: #ffffff;
}

.keywording-block .estest_upload__input {
	color: #ffffff;
}

.keywording_estest.estest_result {
	padding-top: 0;
	margin-bottom: 100px;
}

.keywording_estest .estest_info__title {
	color: #1a1a1a;
	margin-top: 0;
	max-width: 695px;
	font-size: 3.1vw;
	line-height: 1.3;
}

.keywording_estest .estest_info__text {
	color: #1a1a1a;
	margin-top: 31px;
	max-width: 720px;
	line-height: 41px;
}

.keywording-block .estest__samples__list{
	justify-content: space-between;
}

.keywording-block .estest__samples__title{
	font-size: 28px;
}
.keywording-block .estest__samples__item {
 	margin-right: 34px;
}

.keywording-block .estest__samples__item {
	height: 82px;
}

.keywording-block .estest__samples__item img{
	width: 10vw;
	height: 5.55vw;
}

.keywording-block .estest__samples__item:last-child {
	margin-right: 0;
}

.keywording-block .icon-photo:before {
    content: "\e90c";
    color: #fff;
}

.keywording-block .estest_result  .btn-plain {
	border: 1px solid #1A1A1A;
}

.keywording-block  .estest_suggest__list_wrap {
	border: 1px solid #1A1A1A;
}

.keywording-block .estest_suggest__list {
	color: #1a1a1a;
}

.keywording-block .estest_suggest__item {
	border: 1px solid #1A1A1A;
}

.estest_results.desktop_version .keywording-block .estest_upload .icon-photo:before {
    color: #1a1a1a;
    content: "\e90c";
}

.estest_results.desktop_version .keywording-block  .estest_upload {
    border: 1px dashed #1a1a1a;
	height: 85px;
}	

.estest_results.desktop_version .keywording-block  .estest_upload .btn-plain {
    border: 1px solid #1a1a1a;
}

.estest_results.desktop_version .keywording-block .estest_upload__text {
    color: #1a1a1a;
}

.estest_results.desktop_version .keywording-block .estest_result {
    padding-top: 17px;
}

.estest_results.desktop_version .keywording-block .estest_upload__input {
	background: #fff;
}

.estest_results.desktop_version .keywording-block  .estest_result  .estest_result__btn:hover {
    color: #1a1a1a;
}

.cases-block.keywording-block {
	padding-bottom: 50px;
}

@media screen and (min-width: 1441px) {

	.keywording-block .list_block {
		gap: 5.4vw;
		margin-bottom: 73px;
	}

	.keywording-block .list_item {
		flex-basis: calc(50% - (5.4vw / 2));
	}
	
	.keywording-block .list_item .press_item__title {
		font-size: 2.44vw;
	}
	
	.keywording-block .list_item .press_item__text {
		font-size: 1.825vw;
		min-height: 17.32vw;
		height: 19.6vw;
		padding: 1.96vw 1.56vw 1.29vw 2.64vw;
	}

	.keywording-block .about_block .text_block {
		width: 62.72vw;
		margin-right: 5.11vw;
	}

	.keywording-block .press_title {
		font-size: 3.31vw;
		line-height: 4.91vw;
		width: 49.86vw;
	}
	
	.keywording-block .about_block .text_block .about_text {
		max-width: 100%;
		font-size: 1.9vw;
		line-height: 2.83vw;
		margin-top: 1.17vw;
	}

	.border_block {
		width: 21.74vw;
		height: 26.63vw;
	}

	.keywording_items {
		width: 100%;
		margin-bottom: 9px;
		gap: 2.76vw;
	}
	
	.keywording_item .press_item__title {
		font-size: 2.48vw;
		width: 100%;
		max-height: 18.87vw;
		padding: 40px 0 10px;
		min-height: 12vw;
		margin-bottom: 32px;
	}
	
	.keywording_item .press_item__text {
		width: 90%;
		font-size: 1.7vw;
	}

	.keywording_item:nth-child(3) .press_item__text {
		width: 100%;
		font-size: 1.7vw;
	}

	.keywording-block .api_price__item {
		width: 33.33%;
		min-height: 32.98vw;
	}

	.keywording-block .api_price__item__name {
		font-size: 2.48vw;
	}
	
	.keywording-block .api_price__item__descr {
		font-size: 1.38vw;
	}
	
	.keywording-block .api_price__item__info {
		font-size: 1.93vw;

	}

	.list_block {
		width: 100%;
		gap: 4vw;
		margin-bottom: 73px;
	}
	
	.list_item {
		width:50%;
	}
	
	.list_item .press_item__title {
		margin-bottom: 25px;
		min-height: 0;
	}
	
	.list_item .press_item__text {
		font-size: 1.8vw;
		min-height: 17.63vw;
		padding: 35px 38px 19px 39px;
		border: 2px solid #7000FF;
	}

	.cases-block .press_text {
		margin-bottom: 1.93vw;
		font-size: 3.31vw;
		width: 88vw;
	}

	.keywording-block .estest_figure {
		height: 35.1vw;
		overflow: hidden;
	}
	
	.keywording-block .estest_main {
		width: 35%;
	}

	.keywording-block .estest__samples__title{
		font-size: 1.89vw;
	}

	.keywording_estest.estest_result {
		padding-top: 0;
		margin-bottom: 9vw;
	}
	
	.keywording_estest .estest_info__title {
		color: #1a1a1a;
		margin-top: 0;
		font-size: 3.25vw;
		max-width: 47.08vw;
		line-height: 4.8vw;
	}
	
	.keywording_estest .estest_info__text {
		color: #1a1a1a;
		margin-top: 31px;
		font-size: 1.89vw;
		max-width: 48.78vw;
		line-height: 2.77vw;
	}

	.keywording-block .estest__samples__item {
		margin-right: 2.30vw;
   }

   .keywording-block .estest__samples__item {
		height: 5.5vw;
	}
}



/*MAIN PAGE LANDING*/

.main-page_block .api_main__decsr {
	max-width: 935px;
}

.products_block {
	width: 100%;
	display: flex;
}

.dev_block {
	background-color: #1a1a1a;
	color: #FFFFFF;
	padding-top: 28px;
	padding-bottom: 71px;
}

.dev_block a{
	color: #FFFFFF;
}

.creators_block a{
	color: #1a1a1a;
}

.creators_block {
	background-color: #ffffff;
	padding-top: 64px;
	padding-bottom: 34px;
}

.products_block .flex-center {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

.side_items {
	width: 30%;
	height: 545px;
}

.brd_white {
	border: 2px solid #fff;
}

.brd_black {
	border: 2px solid #000000;
}

.products_block .block_one .side_item_1 {
	width: 100%;
	height: 53.85%;
	padding: 32px 42px 0 36px;
	border-width: 2px 2px 0 2px;
}

.products_block .block_one .side_item_2 {
	width: 100%;
	height: 46.15%;
	padding: 32px 42px 0 36px;
}

.products_block .block_two .side_item_1 {
	width: 100%;
	height: 52.47%;
	padding: 32px 42px 0 36px;
	border-width: 2px 2px 0 2px;
}

.products_block .block_two .side_item_2 {
	width: 100%;
	height: 47.53%;
	padding: 32px 42px 0 36px;
}

.side_item__title {
	display: flex;
	align-items: center;
	font-size: 1.63vw;
	line-height: 35px;
	margin-bottom: 20px;
	text-decoration: none;
}

.side_item__title img, .middel_item2__title img{
	width: 1.25vw;
	height: 1.25vw;
	margin-left: 5px;
}

.creators_block .side_item__title img, .creators_block .middel_item2__title img{
	filter: invert(3%) sepia(7%) saturate(5%) hue-rotate(314deg) brightness(0%) contrast(85%);
}

.side_item__text {
	font-size: 1.22vw;
	line-height: 27px;
}

.middel_items {
	width: 40%;
	height: 545px;
}

.middel_item1 {
	width: 100%;
	height: 60%;
	padding: 0 19px 0 33px;
	text-align: center;
}

.middel_item1__title {
	font-size: 3.4vw;
	line-height: 74px;
	margin-top: -5px;
}

.middel_item1__text {
	font-size: 1.63vw;
	line-height: 35px;
}

.middel_item2 {
	width: 100%;
	height: 40%;
	padding: 1.5vw 2.3vw 0 3.11vw;
	border-width: 2px 0 2px 0 2px;
}

.middel_item2__title {
	font-size: 1.63vw;
	margin-bottom: 20px;
	text-decoration: none;
}

.middel_item2__text {
	font-size: 1.22vw;
	letter-spacing: -0.01em;
}

.creators_block .middel_item2__text {
	font-size: 1.22vw;
}

.main-page_block.link_block {
	background-color: #fff;
	padding-bottom: 104px;
}

.main-page_block.link_block .link_block__title {
	font-size: 50px;
	line-height: 74px;
}

.main-page_block.link_block .btn {
	color: #000000;
	border: 1px solid #000000;
}

.main_page__link_btn {
	display: none;
}

@media screen and (min-width: 1441px) {
	.main-page_block .api_main__decsr {
		max-width: 63.51vw;
	}

	.side_items {
		height: 37vw;
	}

	.side_item__title {
		font-size: 1.63vw;
		line-height: 2.37vw;
		margin-bottom: 1.35vw;
	}

	.side_item__text {
		font-size: 1.22vw;
		line-height: 1.83vw;
	}

	.middel_items {
		height: 37vw;
	}

	.middel_item1__title {
		font-size: 3.4vw;
		line-height: 5vw;
	}
	
	.middel_item1__text {
		font-size: 1.63vw;
		line-height: 2.37vw;
	}

	.middel_item2__title {
		font-size: 1.63vw;
		margin-bottom: 1.35vw
	}
	
	.middel_item2__text {
		font-size: 1.22vw;
	}

	.main-page_block.link_block .link_block__title {
		font-size: 3.4vw;
		line-height: 5vw;
	}
}

@media screen and (min-width: 1441px) {
	.press_title {
		font-size: 5vw; /* 72px */
	}

	.press_descr {
		max-width: 52.8vw; /* 760px */
	}

	.press_item__title {
		max-width: 31.95vw;
		min-height: 9vw;
	}

	.press_item {
		width: auto;
		max-width: none;
	}

	.press_item__note,
	.press_item__text {
		max-width: 31.95vw;
	}

	.press_item__text {
		font-size: 1.25vw; /* 18px */
	}

	.press_mission__text,
	.api_price__item__info,
	.contactus_content__more {
		font-size: 1.67vw; /* 24px */
	}
	
	.press_mission__quoute {
		width: 60vw;
	}

	.press_item__text-card {
		font-size: 1.13vw;
	}

	.api_comments__slide {
		min-width: 1170px;
	}

	.api_comments__slide__title {
		font-size: 3.35vw;
	}

	.api_comments__slide__text,
	.api_comments__slide__author {
		font-size: 1.5vw;
	}

	.api_price__item__name {
		font-size: 2.23vw; /* 32px */
		/*font-size: 1.945vw; /* 28px */
	}

	.api_price__item__descr {
		font-size: 0.98vw; /* 14px */
	}

	.about_nav__item {
		font-size: 1.05vw;
	}



		/* AESTHETICS */
	
	.estest {
		padding-bottom: 6.744vw;
	}

	.estest_main {
		/* max-width: 710px; */
	}

	.estest_figure {
		height: 38.2vw;
	}

	.estest_upload__btn {
		width: 5.9vw; /* 85px */
		height: 5.9vw; /* 83px */
		line-height: 6vw; /* 87px */
		bottom: 3vw;
	}

	.estest_upload__btn.filled {
		border: 3px solid #7000ff;
	}

	.estest_info__title,
	.estest_info__text {
		max-width: 45.8vw; /* 660px */
	}

	.estest_upload__text {
		bottom: 7.05vw; /* 101px */
	}

	.estest_upload__input_wrap {
		width: 17vw; /* 245px */
		height: 2.65vw; /* 38px */
		bottom: 4.6vw; /* 66px */
	}

	.estest_upload__input {
		height: 1.945vw;
		padding: 0 2.2vw 0 .4vw;
	}

	.helper-estest {
		width: 1.5vw; /* 22px */
		height: 1.5vw;
		line-height: 1.7vw;
	}

	.estest_upload__formats {
		font-size: 0.845vw; /* 12px */
		bottom: 1.041vw;
	}

	.helper::before {
		font-size: .98vw;
	}

	.estest_results .estest_upload__formats {
		left: 1.736vw;
	}

	.estest_socials {
		width: 33vw;
	}

	.estest_info__text {
		margin-top: 2.77vw;
	}

	.estest__samples {
		margin-top: 2.43vw;
	}


	.estest_results.desktop_version .estest_upload {
		height: 5.9vw;
		padding-left: 1.38vw;
		margin-left: 6.111vw;
		box-sizing: border-box;
	}

	.estest_results.desktop_version .estest_upload__btn {
		left: -6.111vw;
	}

	.estest_results .estest_upload__input_wrap {
		width: 9.625vw;
	}

	.estest_result__score {
		font-size: 9.99vw;
	}

	.estest_info__tip {
		line-height: 1.11vw;
	}

	.estest_suggest {
		min-height: 19.44vw;
	}


	.esinfo_title {
		max-width: 32.63vw;
		line-height: 3.75vw;
	}

	.esinfo_text {
		max-width: 43vw;
		padding-left: 34.72vw;
	}

	.esinfo_li:first-child {
		max-width: 42.77vw;
	}

	.esinfo_count {
		font-size: 4.3vw;
		left: 27vw;
	}

	.esinfo_li__number {
		font-size: 3.263vw;
		left: -3.263vw;
	}

	.esinfo_cit {
		width: 52.08vw;
		height: 52.08vw;
	}

	.subscr_form-estest {
		margin-bottom: 4.86vw;
	}

	.container-hires .subscr_form {
		max-width: 41.666vw;
		height: 4.84vw;
	}



	.contactus_content__text {
		font-size: 2.5vw; /* 36px */
	}

	.about_contacts__email_link {
		font-size: 3.34vw; /* 48px */
	}
}

.add_emails p, .add_emails input, .add_emails button {
  display: inline-block !important;
  width: auto !important;
  margin-bottom: 10px;
}

.add_emails button  {
 	margin-bottom: 3px;
}

.add_emails strong, input {
  margin-right: 10px;
}

.glyphicon-trash {
  color: #b31d1d;
  cursor: pointer;
}

.invalid_email {
  color: #b31d1d;
}

.email_group {
  margin-top: 5px;
}

.email_item {
  display: inline-block;
  margin-right: 5px;
}

.email_delete {
  color: red;
  cursor: pointer;
}

.error_msg {
	color: #b31d1d;
	margin-bottom: 10px;
}

.alert_btn, .alert_input {
  display: inline-block !important;
  width: auto !important;
}

.alert_text {
  margin-bottom: 15px;
}

.invalid_input {
  color: #b31d1d;
}

.requests_table {
  margin-top: 15px;
  margin-bottom: 15px;
}

.requests_table  td {
  border-top: 1px solid #ccc;
}

.requests_table td, .requests_table th {
  padding: 5px 40px 5px 0px;
  font-size: 15px;
}

.pass_input {
  width: 30% !important;
  margin-bottom: 5px;
}

.info_row {
	display: block;
}

.info_title {
	width: 20%;
	display: inline-block;
}

.info_input {
	display: inline-block !important;
	width: 40% !important;
	margin-bottom: 5px;
}

.short_push {
  max-width: 730px;
  margin: 0 auto;
}

.search_title {
	margin-bottom: 20px;
	font-size: 30px;
}

.search_row {
	display: flex;
	flex-direction: row;
}

.search_item {
	max-height: 120px;
	display: inline-flex;
	border: 2px solid #fff;
}

.search_img {
	width: 100%;
	height: 100%;
	display: flex;
}

.thumbs {
	margin-left: -5px;
	font-size: 0;
	will-change: contents;
}

.thumbs_row {
	white-space: nowrap;
}

.thumb {
	margin: 0 0 5px 5px;
	display: inline-block;
	position: relative;
	overflow: hidden;
	background: #d8d8d8;
}

.thumb__img {
	max-width: 100%;
	display: inline-block;
	vertical-align: top;
}

.search_res_btn {
	margin-top: 20px;
}

.api_terms {
  font-family: CirceWebRegular,sans-serif;
  font-size: 14px;
  font-size: 1.4em;
  line-height: 1.5;
  overflow-x: hidden;
  color: #000;
  background: #fff;
  min-height: 800px;
  overflow-y: scroll;
}

.api_terms__body {
  color: #000;
  position: absolute;
  top: 50px;
  right: 17px;
  bottom: 50px;
  left: 38px;
  overflow: auto;
  overflow-x: hidden;
}

.api_terms__body a {
	color: #6400e5;
  border-bottom: 1px solid #e5cfff;
}

.api_terms h2 {
  font-size: 42px;
  font-family: CirceWebLight,sans-serif;
  margin-bottom: 20px;
  color: #222;
  font-weight: 400;
}

.api_terms h3 {
  font-size: 29px;
  font-family: CirceWebLight,sans-serif;
  margin-bottom: 20px;
  line-height: 36px;
  font-weight: 400;
}

.api_terms p+h3 {
	margin-top: 20px;
}

.api_terms p {
	line-height: 20px;
	font-size: 16px;
}

.api_terms p+p {
	margin-top: 20px;
}



/* UGC LANDING */

body.ugc_body {
	overflow-x: hidden;
}

.ugc_body .account_header,
.ugc_body .header {
	background: inherit;
}

.ugc_nav {
	position: fixed;
	color: #8A8A8A;
	transform: rotate(90deg);
  top: 27vw;;
  right: -17vw;
  z-index: 100000;
}

.ugc_nav_list {
	display: flex;
	font-size: 1vw;
	background: rgba(0,0,0,0);
}

.ugc_nav_item {
	list-style: none;
	margin: 0 .3vw;
	cursor: pointer;
	transition: all .5s ease;
}

.ugc_nav_item.active {
	color: #0eb;
}

body.ugc_body .menu__submenu {
	background: inherit;
}

section.ugc_screen {
	width: 100%;
	min-height: 100vh;
	transition: all .6s ease;
	padding-bottom: 4vw;
	overflow-x: hidden;
}

body.wide section.ugc_screen {
	height: auto;
}

body.wide #seventh .seventh_screen_content {
	height: auto;
}

section.ugc_screen.active {
}

section.first_screen {
	background: url('../i/ugc/ugc-1.png');
	background-size: cover;
	color: #fff;
}

.first_screen_content {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.first_screen_left,
.first_screen_right {
	display: inline-flex;
	flex-direction: column;
	width: 40%;
	margin-top: 4vw;
	justify-content: space-between;
}

.first_screen_left {
	padding-bottom: 3vw;
}

.first_screen_right {
	width: auto;
	border-top: .1vw solid #fff;
	border-radius: .1vw;
	margin-right: 4vw;
}

.first_screen_title {
	font-size: 10vw;
	line-height: 1;
	margin-bottom: 2vw;
}

.first_screen_model {
	font-size: 3.2vw;
	padding-top: 4vw;
	font-weight: 200;
	line-height: 1.1;
	margin-bottom: 1vw;
}

.model_svg {
	height: 2.4vw;
}

.first_screen_algorithm {
	width: 38vw;
  font-size: 1.8vw;
  margin-bottom: 3vw;
}

.first_screen_eyes {
	width: 35%;
	display: flex;
	font-size: 3vw;
	cursor: pointer;
}

.arrow-svg {
	height: 2.6vw;
	margin-right: 2vw;
	margin-top: 1vw;
	position: relative;
}

.eyes_wrap {
	height: 4vw;
}

.left_eye,
.right_eye {
	display: inline-flex;
  height: 1.4vw;
  width: 1.4vw;
  box-shadow: 0 0 0 0.22vw;
  border-radius: 2vw;
  position: relative;
  top: -1.1vw;
  justify-content: center;
  transition: height .5s ease, top .5s ease;
}

.left_eye span,
.right_eye span {
	display: block;
	background: #fff;
	width: .4vw;
	height: .4vw;
	border-radius: 1vw;
	position: relative;
	margin-left: -1vw;
}

.left_eye {
  margin-left: .4vw;
}

.right_eye {
	margin-right: .3vw;
}


.first_screen_eyes:hover .left_eye,
.first_screen_eyes:hover .right_eye,
.left_eye:hover,
.right_eye:hover {
	height: .9vw;
	top: -.9vw;
}


/* SECOND SCREEN */
section.second_screen {
	background: #000;
	color: #fff;
}

.second_screen_content {
	width: 90%;
	border-top: .1vw solid #fff;
	margin: 0 auto;
	margin-top: 4vw;
	padding-top: 4vw;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.second_screen_title {
	font-size: 2.6vw;
 	width: 55%;
  padding-right: 19vw;
}

.second_screen_inputs {
	display: flex;
	margin-top: 4vw;
  width: 100%;
  flex-wrap: wrap;
}

.image_score {
	display: none;
	font-size: 1.5vw;
  width: 100%;
  margin-bottom: 1vw;
}

.score_percent {
	font-size: 8.5vw;
	display: block;
	height: 11vw;
}

.scoring_results .second_screen_inputs {
	width: 50%;
	margin-top: 0;
}

.scoring_results .second_screen_preview {
	display: block;
}

.scoring_results .second_screen_title {
	display: none;
}

.scoring_results .image_score {
	display: block;
}

.second_screen_preview {
	display: none;
	width: 29%;
  margin-right: 9vw;
}

.ugc_figure {
	height: 100%;
  width: 100%;
}

.ugc_preview {
	display: block !important;
}

.url_input {
	width: 60%;
  padding: 1.2vw;
  box-shadow: 0 0 0 0.1vw #8A8A8A;
  font-size: 1.2vw;
  font-weight: 100;
  height: 11.5vw;
}

.url_input_dash {
	border: 1px dashed #0eb;
  padding: 2.2vw;
}

.url_decription {
	margin-bottom: .5vw;
}

.url_input input {
  background: #1A1A1A;
  border: none;
  padding-left: .4vw;
  padding-top: .7vw;
  width: 100%;
  position: relative;
  z-index: 2;
}

.file_input {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  justify-content: space-evenly;
  width: 30%;
  box-shadow: 0 0 0 0.1vw #fff;
  text-align: center;
  color: #8A8A8A;
  padding: 2vw;
  cursor: pointer;
  height: 11.5vw;
  margin-left: .1vw;
}

.file_input:hover {
	 box-shadow: 0 0 0 0.1vw #7000ff;
}

.file_input img {
	width: 2.2vw;
	margin: 0 auto;
}

.second_screen_samples {
	display: flex;
  flex-direction: column;
  margin-top: 2vw;
}

.samples_title {
	font-size: 1vw;
	margin-bottom: 1vw;
}

.samples_img_wrap img {
	max-height: 8.9vw;
	cursor: pointer;
	z-index: 1;
	position: relative;
}

.samples_img_wrap {
	display: inline-block;
	margin-right: 1vw;
	height: 8.9vw;
	z-index: 2;
	position: relative;
}


/* THIRD SCREEN */

section.third_screen {
	background: #fff;
	color: #1a1a1a;
}

.third_screen_content {
	width: 90%;
	margin: 0 auto;
	margin-top: 4vw;
	padding-top: 3vw;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.third_screen_columns {
	display: flex;
	justify-content: space-between;
}

.third_screen_column {
	width: 50%;
	font-size: 1.6vw;
}

.third_screen_column:first-of-type {
	font-size: 2.57vw;
	width: 48%;
}

.third_screen_column:last-of-type {
	border-top: .1vw solid #8A8A8A;
	width: 44%;
	margin-top: 1vw;
}

.third_screen_column_top span {
	color: #7000ff;
}

.third_screen_column_bottom {
	margin-top: 5vw;
}

.third_screen_note {
	color: #747474;
	margin-top: 2.5vw;
	margin-bottom: 3.5vw;
}

.third_screen_column_title {
	font-size: 6.5vw;
	margin-top: 2vw;
}


/* FOURTH SCREEN */

section.fourth_screen {
	background: #fff;
	color: #1a1a1a;
}

.fourth_screen_content {
	width: 90%;
	margin: 0 auto;
	margin-top: 4vw;
}

.fourth_screen_title {
	font-size: 4vw;
	width: 50%;
	padding-top: 2vw;
	margin-bottom: 5vw;
}

.fourth_screen_cases {
	display: flex;
	flex-wrap: wrap;
}

.fourth_screen_case {
	width: 50%;
	margin-right: 4vw;
	margin-bottom: 5vw;
}

.fourth_screen_case:nth-child(even) {
	width: 29vw;
}

.fourth_screen_case:nth-child(odd) {
	width: 44.3vw;
}

.fourth_screen_case:nth-child(3) img,
.fourth_screen_case:nth-child(4) img {
	width: 24.5vw;
}


.case_img {
	margin-bottom: 2vw;
}

.case_title {
	font-size: 2.57vw;
  margin-bottom: 1vw;
}

.case_title span {
	display: inline-block;
  box-shadow: 0 0 0 0.2vw #7000ff;
  padding: 0 .6vw;
}

.case_title img {
	height: 5vw;
	margin-top: -.3vw;
}

.case_text {
  font-size: 1.67vw;
}

.first_screen_btn {
	margin: 0 auto;
  display: block;
  font-size: 2.57vw;
  border: .2vw solid;
  padding: 1.5vw 7vw;
  transition: all .1s ease;
}

.first_screen_btn:hover {
	border-color: #7000ff;
}

/* FIFTH SCREEN */

section.fifth_screen {
	background: #000;
	color: #fff;
}

.fifth_screen_content {
	width: 90%;
	margin: 0 auto;
	margin-top: 4vw;
}

.fifth_screen_title {
	font-size: 3vw;
	margin-bottom: 2vw;
}

.fifth_screen_main {
	height: 37vw;
	width: 90%;
	display: none;
	justify-content: space-between;
}

.fifth_screen_main:not(.active) img {
	display: none;
}

.fifth_screen_main.active {
	display: block;
}

.fifth_screen_main img {
	display: inline-block;
	position: absolute;
	max-width: 20vw;
	top: 430vh;
	left: 35vw;
	z-index: 1;
	transition: all .6s ease;
	cursor: grab;
}

.grabbed {
	cursor: grabbing;
}

.ugc_grid_image:hover {
	z-index: 100000 !important;
}

.ugc_grid_image:hover .ugc_grid_image {

}

.grid_single img {
	max-height: 25vw;
}

.grid_btn {
	position: relative;
  z-index: 100000;
  background: #000;
  font-size: 3vw;
  box-shadow: 0 0 0 0.1vw;
  padding: 1vw 7vw;
  left: 27vw;
  top: -20vw;
}

.grid_btn:hover {
	background: #7000ff;
	box-shadow: 0 0 0 0.1vw #7000ff;
}

.sorted_grid {
	display: none;
}

.sorted_grid.active {
	display: block;
}

.class_images {
	display: none;
}

.class_images.active {
	display: flex;
}

.class_images img {
	width: auto !important;
	max-height: 15vw;
	margin-right: 1vw;
}

.class_description {
	display: none;
	justify-content: space-between;
	margin-bottom: 2vw;
}

.class_description.active {
	display: flex;
}

.class_btns {
	margin-bottom: 2vw;
	display: flex;
}

.class_btn,
.rand_btn {
	width: 13vw;
  font-size: 2vw;
  box-shadow: 0 0 0 0.1vw;
  padding: .5vw 0;
}

.class_btn:hover,
.rand_btn:hover,
.mobile_rand_btn:hover {
	background: #7000ff;
	box-shadow: 0 0 0 .1vw #7000ff;
}

.mobile_rand_btn {
	display: none;
}

.class_btn.active {
	box-shadow: 0 0 0 .1vw #7000ff;
}

.rand_btn {
	width: 23vw;
}

.score_range {
  width: 55%;
  font-weight: 200;
  font-size: 9vw;
}

.score_description {
	font-size: 1.6vw;
  width: 40%;
}



/* SIXTH SCREEN */

section.sixth_screen {
	background: #fff;
	color: #1a1a1a;
	position: relative;
}

.sixth_screen_content {
	width: 90%;
	margin: 0 auto;
	margin-top: 4vw;
	padding-bottom: 2vw;
	display: flex;
	justify-content: space-between;
	border-top: .1vw solid #1a1a1a;

}

.sixth_screen_description {
	width: 28.5%;
}

.description_title {
	font-size: 4vw;
	padding-top: 2vw;
	margin-bottom: 2vw;
}

.description_text {
	display: flex;
	justify-content: space-between;
}

.description_left_column {
	font-size: 1.5vw;
}

.description_right_column {
	font-size: 1vw;
	margin-top: 1vw;
}

.code_left_rule {
	margin-top: 1.5vw;
}

.sixth_screen_code {
	display: flex;
	justify-content: space-between;
	margin-top: 3vw;
	width: 60%;
}

.sixth_screen_code .csBar {
	right: 4.16vw !important;
}

.code_left_column {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.code_right_column {
	width: 95%;
	margin-right: 5vw;
}

.code_title {
	font-size: 2vw;
	margin-bottom: 1vw;
	border-top: .1vw solid;
	margin: 1.5vw 0;
	padding-top: 1vw;
}

.code_text {
	font-size: 1vw;
}

.code_button {
  background: #7000ff;
  color: #fff;
  font-size: 1vw;
  padding: 1vw;
  margin: 2vw 0;
  width: 100%;
}

.code_button:hover {
	background: #fff;
	color: #000;
	box-shadow: 0 0 0 .1vw #000;
}

.code_examples {
	font-size: 1vw;
}

.ugc_pre {
	background: inherit;
	font-size: .8vw;
	padding: 1.5vw;
	border-top: none;
}

.answer_pre {
	height: auto;
	border-top: 1px solid #ccc;
}

.code_answer_title {
	font-size: 1vw;
  margin: 1.5vw 0;
}

section.seventh_screen {
	background: #1a1a1a;
	color: #fff;
	padding-bottom: 0;
}

.seventh_screen_content {
	width: 100%;
	margin: 0 auto;
	margin-top: 4vw;
}

.seventh_screen_title {
	font-size: 3vw;
  width: 60%;
  padding-bottom: 3vw;
  margin-left: 5vw;
}

.seventh_screen_products {
	display: flex;
	justify-content: center;
}

.seventh_screen_products :nth-child(2) {
	margin-left: .1vw;
}

.seventh_screen_product {
	width: 45%;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 0 .1vw #fff;
	justify-content: space-between;
	padding: 2vw 3vw;
	height: 28vw;
	text-decoration: none;
  color: #fff;
	margin-bottom: 4vw;
}

.seventh_screen_product:hover {
	box-shadow: 0 0 0 .1vw #7000ff;
	background: #7000ff;
	cursor: pointer;
	text-decoration: none;
	color: #fff;
}

.product_title {
	font-size: 3vw;
}

.product_title img {
	height: 1.36vw;
}

.product_text {
	font-size: 2vw;
}

.grecaptcha-badge {
	display: none !important;
}


.ugc_footer {
	position: relative !important;
	width: 100%;
	background: #000;
	color: #fff;
	padding-bottom: 2vw;
}

.estland__footer_menu {
  display: flex;
  flex-direction: row;
  padding: 4.69vw 0;
}

.estland__footer_contact {
	width: 40%;
	display: flex;
	flex-direction: column;
	padding-left: 4.17vw;
}

.estland__footer_logo {
	width: 50%;
}

.estland__footer_mail {
	font-size: 1.30vw;
  width: 50%;
  line-height: 1.6vw;
  margin-top: 3vw;
}

.estland__footer_mail a {
	color: #00b08a;
}

.estland__footer_column {
	width: 20%;
}

.estland__column_title {
	font-size: 2.5vw;
	line-height: 2.5vw;
}

.estland__column_ul {
	list-style: none;
	margin-top: .7vw;
}

.estland__column_li {
	font-size: 1.2vw;
	line-height: 2.2vw;
	width: 100%
}

.estland__column_li a {
	color: #fff;
}

.estland__footer_social {
	display: flex;
	flex-direction: row;
}

.estland__footer_copyright,
.estland__social_links {
	width: 50%;
}

.estland__footer_copyright {
  font-size: 1vw;
  padding-left: 5.5vw
}

.estland__socia_link img {
	height: 2.08vw;
}

.estland__social_links {
	display: flex;
	flex-direction: row;
	margin-top: -.5vw;
	font-size: 1.5vw;
}

.link-follow {
	color: #0BEEBC;
	display: inline-block;
	text-decoration: none;
}

.link-follow:hover {
	color: #0BEEBC;
	text-decoration: none;
}

.link-follow::after {
	width: 100%;
	content: '';
	display: inline-flex;
	position: relative;
	height: .1vw;
	background: rgba(11,238,188, 0.8);
	top: -1.7vw;
	transition: all .3s ease;
}

.link-follow:hover::after {
	width: 0%;
}

.loading_cross {
	position: relative;
	height: 3.6vw;
	width: 3.6vw;
  margin: 0 auto;
	z-index: 10;
}

.loading_cross span {
	width: .5vw;
	height: .5vw;
	position: relative;
	background: #fff;
	display: block;
	margin-bottom: .2vw;
}

.loading_cross .inside {
	width: 60%;
	margin: 0 auto;
	-webkit-animation: loading_together 5s infinite ease-in-out;
 	animation: loading_together 5s infinite ease-in-out;
}

.loading_cross .first,
.loading_cross .third,
.loading_cross .sixth,
.loading_cross .eighth {
	float: left;
}

.loading_cross .fifth {
	margin: 0 auto;
	margin-bottom: .2vw;
}

.loading_cross .second,
.loading_cross .fourth,
.loading_cross .seventh,
.loading_cross .nineth {
	float: right;
}

.float_cleaner {
	clear: both;
}

.loading_cross .first {
	-webkit-animation: loading_first 5s infinite ease-in-out;;
	animation: loading_first 5s infinite ease-in-out;
}

.loading_cross .second {
	-webkit-animation: loading_second 5s infinite ease-in-out;
	animation: loading_second 5s infinite ease-in-out;
}

.loading_cross .third {
	-webkit-animation: loading_third 5s infinite ease-in-out;
	animation: loading_third 5s infinite ease-in-out;
}

.loading_cross .fourth {
	-webkit-animation: loading_fourth 5s infinite ease-in-out;
	animation: loading_fourth 5s infinite ease-in-out;
}

.loading_cross .fifth {
	-webkit-animation: loading_fifth 5s infinite ease-in-out;
	animation: loading_fifth 5s infinite ease-in-out;
}


.loading_cross .sixth {
	-webkit-animation: loading_sixth 5s infinite ease-in-out;
	animation: loading_sixth 5s infinite ease-in-out;
}


.loading_cross .seventh {
	-webkit-animation: loading_seventh 5s infinite ease-in-out;
	animation: loading_seventh 5s infinite ease-in-out;
}


.loading_cross .eighth {
	-webkit-animation: loading_eighth 5s infinite ease-in-out;
	animation: loading_eighth 5s infinite ease-in-out;
}

.loading_cross .nineth {
	-webkit-animation: loading_nineth 5s infinite ease-in-out;
	animation: loading_nineth 5s infinite ease-in-out;
}

@-webkit-keyframes loading_first {
	0% {
	    /*opacity: 0;
		top: 0;*/
	  }
	15% {
		opacity: 1;
		top: 0;
	}

	20% {
		opacity: 1;
		top: 0;
	}

	40% {
		top: -.5vw;
		left: 0;
	}

	50% {
		left: -.5vw;
	}

	100% {
		opacity: 1;
		top: -.5vw;
		left: -.5vw;
		
	}
}

@-webkit-keyframes loading_second {
	0% {
	    /*opacity: 0;
		top: 0;*/
	  }
	20% {
		opacity: 1;
		top: 0;
	}

	40% {
		top: -.5vw;
		right: 0;
	}

	50% {
		right: -.5vw;
	}

	100% {
		top: -.5vw;
		right: -.5vw;
		opacity: 1;
	}
}

@-webkit-keyframes loading_fifth {
	0% {
	    opacity: 0;

	  }
	5% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes loading_sixth {
	0% {
	    opacity: 0;
	  }
	60% {
		opacity: 0;
	}
	70% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes loading_seventh {
	0% {
	    opacity: 0;
	  }
	60% {
		opacity: 0;
	}
	70% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

/********************************/

@-webkit-keyframes loading_third {
	0% {
	    opacity: 0;
	  }
	60% {
		opacity: 0;
	}
	70% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}


@-webkit-keyframes loading_fourth {
	0% {
	    opacity: 0;
	  }
	60% {
		opacity: 0;
	}
	70% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}


@-webkit-keyframes loading_eighth {
	0% {
	    /*opacity: 0;
	    bottom: 0;
	    left: 0;*/
	  }
	10% {
		opacity: 1;
	}

	25% {
		bottom: 0;
	}

	40% {
		bottom: -.5vw;
		left: 0;
	}

	50% {
		left: -.5vw;
	}

	100% {
		bottom: -.5vw;
		left: -.5vw;
		opacity: 1;
	}
}


@-webkit-keyframes loading_nineth {
	0% {
	   /* opacity: 0;
	    bottom: 0;
	    right: 0;*/
	  }
	25% {
		opacity: 1;
		bottom: 0;
	}

	40% {
		bottom: -.5vw;
		right: 0;
	}

	50% {
		right: -.5vw;
	}

	100% {
		right: -.5vw;
		bottom: -.5vw;
		opacity: 1;
	}
}

@-webkit-keyframes loading_together {
	80% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-360deg);
	}
}

.first_screen_key_btn {
	width: 79%;
  display: flex;
  font-size: 1.25vw;
  justify-content: space-between;
  box-shadow: 0 0 0 0.1vw #fff;
  padding: 1vw;
  margin-left: .5vw;
  transition: all .1s ease;
}

.first_screen_key_btn:hover {
	box-shadow:  0 0 0 .2vw #7000ff;
}


/* AGE ESTIMATION */

.age_title {
	font-size: 4.1vw;
	padding: 2.34vw 0;
	line-height: 3.75vw;
}

.age_results {
	max-width: 549px;
	max-height: 285px;
	overflow: auto;
	width: 100%;
	box-shadow: 
		1px 0 0 0 #fff, 
		0 1px 0 0 #fff, 
		1px 1px 0 0 #fff, 
		inset 1px 0 0 0 #fff, 
		inset 0 1px 0 0 #fff;
}

.age_results li {
	width: 99.8%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	text-decoration: none;
	font-size: 1.25vw;
	position: relative;
	z-index: 1;
}

.age_person {
	height: 2.5vw;
    align-items: center;
    box-shadow: 
 		1px 0 0 0 #fff, 
  		0 1px 0 0 #fff, 
  		1px 1px 0 0 #fff,
 	 	1px 0 0 0 #fff inset, 
  		0 1px 0 0 #fff inset;
    padding: 0 .73vw;
    transition: all .3s ease;
}

.age_person:hover {
	cursor: pointer;
	z-index: 2;
	box-shadow: 
 		2px 0 0 0 #7000FF, 
  		0 2px 0 0 #7000FF, 
  		2px 2px 0 0 #7000FF,
 	 	2px 0 0 0 #7000FF inset, 
  		0 2px 0 0 #7000FF inset;
}

.estest_result-age .estest_result__btn  {
	z-index: 1;
	margin-top: 1px;
	max-width: 549px;
	width: 100%;
	border: none;
	box-shadow: 
 		1px 0 0 0 #fff, 
  		0 1px 0 0 #fff, 
  		1px 1px 0 0 #fff,
 	 	1px 0 0 0 #fff inset, 
  		0 1px 0 0 #fff inset;
}

.estest_faceframe {
	position: absolute;
	border: 2px solid #45FF26;
	display: flex;
	align-items: flex-end;
	transition: all .3s ease;
}

.estest_faceframe.selected {
	border-color: #7000FF;
}

.estest_faceframe div {
	color: #45FF26;
	font-size: 14px;
    width: 29px;
    height: 29px;
    position: relative;
    top: 31px;
    right: 2px;
   	background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease;
}

.estest_faceframe.selected div {
	background: #7000FF;
	color: #fff;
}

.estest_faces_wrap {
	position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: fit-content;
    width: fit-content;
    max-width: 100%;
    max-height: 100%;
}

.estest_faces_wrap .estest_preview {
	position: relative;
}



:root {
  --back-color: #1a1a1a;
  --color: #fff;
}

a {
  text-decoration: none;
  border: none;
  outline: none;
}

a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}

.api__row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
}

.api__age {
  display: flex;
  flex: 1;
  background-color: var(--back-color);
  color: var(--color);
  padding: 35px;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: all .7s ease;
}

.api__age_info, .api__age_result {
  display: flex;
  flex: .9 1280px;
  flex-flow: column wrap;
  order: 1;
}

.api__age_result {
  position: relative;
  padding-top: 0px;
}

.api__age_result.upload {
  padding-top: 55px;
}

.api__age_results .api__age_header {
  position: absolute;
  top: 30px;
}

.api__age_header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 80%;
  flex-wrap: wrap;
}

.api__age_header__logo svg {
  width: 100%;
}

.api__age_header__home {
  display: none; 
}

.api__age_header__home:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2.9px;
  background-color: var(--color);
  top: 29px;
  transition: width .3s ease;
}

.api__age_header__home:hover:after {
  width: .15vw;
}

.api__age_info__title {
  font-size: 26px;
  line-height: 117%;
  z-index: 100;
  padding-top: 35px;
  word-break: break-word;
}

.api__age_info__title span {
  position: relative;
  display: inline-flex;
}

.api__age_info__title span:after {
  content: "";
  display: block;
  position: absolute;
  background-image: url('../i/age/title.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 150px;
  height: 49px;
  left: -10px;
  bottom: -10px;  
  z-index: -1;
}

.api__age_info__descr {
  font-size: 22px;
}

.api__age_info__descr_content {
  display: flex;
}

.api__age_info__descr_title {
  position: relative;
  padding-top: 35px;
  padding-bottom: 20px;
}


.api__age_info__descr_text {
  display: flex;
  flex: 1 360px;
  flex-direction: column;  
  padding: 20px 0;
  border-top: 2px solid var(--color);
}

.api__age_info__samples {
  display: none;
}

.api__age_info__samples_image {
  flex: 1 100%;
  justify-content: center;
  text-align: center;
}

.api__age_info__samples_image img {
  width: 100%;
  max-width: 100%;
}

.api__age_info__samples_count {
  display: inline-flex;
  float: left;
  font-size: 24px;
  line-height: 1.6;
}

.api__age_info__samples_arrows {
  display: inline-flex;
  float: right;
  font-size: 38px;
  line-height: 1;
}

.api__age_info__samples_arrows div {
  width: 38px;
  transition: all .3s ease;
}

.api__age_info__samples_arrows div:last-of-type {
  text-align: right;
}

.api__age_info__samples_arrows div:hover {
  cursor: pointer;
  color: #7000ff;
}

.api__age_upload {
  border: none;
  margin-top: 10px;
  top: auto;
  right: 0;
  bottom: auto;
  left: 0;
  position: relative;
}

.api__age_footer_copyright,
.api__age_footer_socials {
  display: flex;  
}

.api__age_footer_copyright {
  word-break: break-word;
}

.api__age_footer_socials {
  flex-wrap: wrap;
}

.api__age_footer_socials_item {
  position: relative;
  color: var(--color);
  margin-right: 20px;
}

.api__age_footer_socials_item:after {
  content: '';
  display: flex;
  position: absolute;
  width: 100%;
  height: 2px;
  top: 30px;
  background-color: var(--color);
  transition: all .3s ease;
}

.api__age_footer_socials_item:hover:after {
  width: 0;
}

.api__age footer.api__age_footer {
  display: none;
}

footer.api__age_footer {
  font-size: 20px;
  line-height: 38px;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex: 1 100%;
  padding-top: 20px;
  flex-wrap: wrap;
  order: 3;
}

.api__age_preview {
  position: relative;
}

.api__age_bg {
  box-shadow: unset;
  padding: 0;
}


.api__age_preview_bg {
  height: 250px;
  filter: blur(10px);
  background-size: cover;
}

.api__age_figure_bg {
  display: none;
}

.api__age_preview {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.api__age_preview_image {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.api__age_upload_btn  {
  box-shadow: 0 0 0 1px #fff;
  font-size: 29px;
  padding: 18px;
  text-align: center;
}

.api__age_upload.filled
.api__age_upload_btn {
  box-shadow: 0 0 0 1px var(--color);
}

.api__age_upload_btn:before {
  display: none;
}

.api__age_upload_inputs {
  display: none;
}

.api__age_results {
  display: flex;
  flex: .9 1280px;
  flex-flow: column wrap;
  order: 2;
}

.api__age_results_title {
  padding-top: 30px
}

.api__age_results_count {
  font-size: 26px;
  line-height: 117%;
  padding-bottom: 10px;
}

.api__age_results_persons_single {
  font-size: 22px;
}

.api__age_results_persons_single svg {
  height: 30px;
  width: 30px;
  margin-left: 10px;
}

.api__age_results_main {
  display: flex;
  flex-flow: row wrap;
  padding-top: 30px;
}

.api__age_results_text {
  display: flex;
  font-size: 22px;
  line-height: 27px;
  padding-top: 30px;
  border-top: 2px solid var(--color);
  flex: 1 100%;
  padding-bottom: 30px;
}

.api__age_results_share {
  box-shadow: 
    1px 0 0 0 #0BEEBC, 
  	0 1px 0 0 #0BEEBC, 
  	1px 1px 0 0 #0BEEBC,
    1px 0 0 0 #0BEEBC inset, 
  	0 1px 0 0 #0BEEBC inset;
  font-size: 22px;
  display: flex;
  line-height: 15px;
  flex: 1 100%;
  flex-flow: row wrap;
}

.api__age_results_share_title {
  display: flex;
  flex: 1 100%;
  text-align: center;
  justify-content: center;
  padding: 22px 35px;
  line-height: 30px;
}

.api__age_results_socials  {
  width: 100%;
  height: 130px;
}

.api__age_results_socials:hover {
  cursor: pointer;
}

.api__age_results_share_item {
  display: flex;
  flex: 1 50%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding:  14px 0;
  box-shadow: 
    1px 0 0 0 #0BEEBC, 
  	0 1px 0 0 #0BEEBC, 
  	1px 1px 0 0 #0BEEBC,
    1px 0 0 0 #0BEEBC inset, 
    0 1px 0 0 #0BEEBC inset;
  background-color: var(--back-color);
  transition: all .3s ease;
}

.api__age_results_share_item:hover {
  cursor: pointer;
  background-color: var(--color);
}

.api__age_results_share_item:hover svg path {
  fill: var(--back-color);
}

.api__age_results_persons_item {
  display: flex;
  justify-content: space-between;
  padding: 7px 14px;
  font-size: 18px;
  box-shadow:
    1px 0 0 0 rgba(255,255,255,.5), 
    0 1px 0 0 rgba(255,255,255,.5), 
    1px 1px 0 0 rgba(255,255,255,.5), 
    1px 0 0 0 rgba(255,255,255,.5) inset, 
    0 1px 0 0 rgba(255,255,255,.5) inset;
}

.api__age_about {
  display: flex;
  flex-flow: column;
  order: 3;
  padding: 25px 35px;
  background-color: var(--color);
  color: var(--back-color);
}

.api__age_about_title {
  font-size: 26px;
  line-height: 122%;
  padding-bottom: 20px;
}

.api__age_about_text {
  font-size: 18px;
  line-height: 140%;
  padding-top: 20px;
  border-top: 2px solid var(--back-color);
}

.api__age_about .api__age_footer_socials_item {
  color: var(--back-color);
}

.api__pushes {
  position: fixed;
  width: 85%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 15vh;
  z-index: 1000;
}

.api__pushes_message {
  color: #1a1a1a;
  background-color: #c5fcb7;
  z-index: 1000;
  text-align: center;
  font-size: 20px;
  padding: 8px 0;
}

.api__pushes_message.error {
  color: #b10009;
  background-color: #fde4e1;
}

body.noscroll {
  overflow: hidden;
}

.api__share {
  background: rgba(0,0,0,.9);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
}

.api__share .api__share_svg_wrap {
  position: relative;
  z-index: unset;
  top: 0;
  height: auto;
}

.api__share_arrow {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4vw;
  width: 6vw;
  transition: all .5s ease;
  color: #fff;
}

.api__share_arrow:hover {
  cursor: pointer;
  background: rgba(255,255,255,0.1);
}

.api__share_svg_wrap {
  position: absolute;
  background-color: #1a1a1a;
  z-index: -1;
  top: -2000px;
  max-width: 100vw;
  height: 1px;
}

.api__share_svg {
  background-color: #1a1a1a;
}

.api__share_preview {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  height: 580px;
  padding: 0 40px;
  background-color: #1a1a1a;
}

.api__share_preview_image_wrap {
  width: 40%;
  position: relative;
  overflow: hidden;
}

.api__share_preview_bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-size: cover;
  filter: blur(6px);
}

.api__share_preview_image {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.api__share_preview_result {
  width: 55.4%;
  color: #fff;
}

.api__share_preview_logo {
	width: 400px;
	height: 36px;
	margin-top: 30px;
}

.api__share_preview_title {
  font-size: 90px;
  display: block;
  font-family: CirceWebLight;
  height: 1.2em;
  overflow: hidden;
  margin-top: 30px;
}

.api__share_preview_title img {
  height: 80px;
  margin-top: -24px;
  margin-left: 10px;
}

.api__share_preview_text {
  font-size: 40px;
  padding-top: 30px;
}


/* Yandex share styles overload */
.ya-share2__container {
  width: 100% !important;
  height: 100% !important;
}

.ya-share2__list {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.ya-share2__item {
  width: 50% !important;
  display: flex !important;
  justify-content: center !important;
  margin: 0 !important;
  height: 100%;
  align-items: center;
  box-shadow: 
    1px 0 0 0 #0BEEBC, 
  	0 1px 0 0 #0BEEBC, 
  	1px 1px 0 0 #0BEEBC,
    1px 0 0 0 #0BEEBC inset, 
    0 1px 0 0 #0BEEBC inset;
}

.ya-share2__link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ya-share2__badge {
  background-color: inherit !important;
}

.ya-share2__icon {
  display: block;
  height: 50px !important;
  width: 50px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  box-sizing: border-box !important;
  background-position: center !important;
}

.ya-share2__item:nth-child(1) .ya-share2__icon {
  background-image: url('../i/age/vk.svg') !important;
}

.api__age.light .ya-share2__item:nth-child(1) .ya-share2__icon {
  background-image: url('../i/age/vk_dark.svg') !important;
}

.ya-share2__item:nth-child(2) .ya-share2__icon {
  background-image: url('../i/age/fb.svg') !important;
}

.api__age.light .ya-share2__item:nth-child(2) .ya-share2__icon {
  background-image: url('../i/age/fb_dark.svg') !important;
}

.api__age_dnd_holder {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	z-index: 99998;
	display: none;
	background: #1a1a1a;
	color: #fff;
	font-size: 24px;
	opacity: 0.9;
}

.api__age_dnd_holder::before {
	content: "";
	background-color: #1a1a1a;
	display: block;
	position: absolute;
	top: 90px;
	right: 30px;
	bottom: 100px;
	left: 30px;
	border: dashed 2px #fff;
}


.dragging .api__age_dnd_holder {
  display: block;
}


@media screen and (min-width: 440px) {
  .api__age_preview_bg {
    height: 450px;
  }
}


@media screen and (min-width: 768px) {
  .api__age {
    padding: 4vw 5vw;
  }
  
  .api__age_info__title {
    font-size: 70px;
    padding-top: 6.25vw;
    line-height: 109%;
  }

  .api__age_info__title span:after {
    width: 353px;
    height: 116px;
    left: -20px;
    bottom: -23px;
  }

  .api__age_info__descr {
    font-size: 24px;
    padding-top: 5vw;
  }

  .api__age_results .api__age_header {
    position: relative;
    top: unset;
  }

  .api__age_header__home {
    font-size: 21px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--color);
    position: relative;
    border: none;
    outline: none;
  }

  .api__age_info__samples {
    display: flex;
    flex: 1 200px;
    flex-flow: row wrap;
    justify-content: space-between;
    position: relative;
    padding-left: 1.6vw;
    padding-bottom: 45px;
  }

  .api__age_info__samples_image {
    height: auto;
    padding-bottom: 1vw;
  }

  .api__age_info__samples_image img:hover {
    cursor: pointer;
  }

  .api__age_result,
  .api__age_result.upload {
    padding-top: 0;
  }

  .api__age_figure {
    display: block;
    box-shadow: 0 0 0 7px #7000ff;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }

  .api__age_figure_bg {
    width: 100%;
    display: block;
    height: 550px;
  }

  .api__age_upload {
    position: absolute;
    height: auto;
    margin-top: 0;
    top: 25px;
    right: 25px;
    bottom: 50px;
    left: 25px;
    border: 1px dashed #fff;
  }

  .api__age_upload.filled {
    border-color: var(--color);
  }
  
  .api__age_info__descr_text {
    border-top: .2vw solid #7000ff;
  }

/*  .api__age_footer_copyright,
  .api__age_footer_socials {
    flex: 1 500px;
  }*/

  .api__age_bg {
    height: auto;
    padding-bottom: 1vw;
  }

  .api__age_preview {
    height: 550px;
  }

  .api__age_preview_bg {
    height: 550px;
  }

  .api__age_upload_btn:before {
    display: block;
    color: #fff;
  }

  .api__age_upload_btn  {
    width: 7.8vw;
    height: 7.8vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: 0 0 0 .05vw #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .api__age_upload_btn span {
    display: none;
  }

  .api__age_upload_inputs {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    position: absolute;
    bottom: 1vw;
    width: 100%;
  }

  .api__age_upload_text {
    display: flex;
    justify-content: center;
    font-size: 24px;
  }

  .api__age_upload__input_file {
    display: none !important;
  }

  .api__age_upload__input_url {
    width: 360px;
    text-align: left;
    background: unset;
    outline: none;
    font-size: 24px;
    border: none;
    margin-right: 0;
    color: #fff;
  }
  
  .api__age_upload__formats {
    width: 90%;
    font-size: 18px;
    border-top: 1px solid var(--color);
    color: rgba(255, 255, 255, 0.6);
    padding-top: 2.5vw;
    text-align: center;
  }

  .api__age_upload_btn:before {
    font-size: 3vw;
  }

  .api__age_upload.filled {
    display: flex;
    height: 120px;
    box-sizing: border-box;
    position: relative;
    right: unset;
    left: unset;
    bottom: unset;
    top: unset;
  }

  .api__age_upload.filled
  .api__age_upload__formats {
    color: var(--color);
    opacity: .8;
  }

  
  .api__age_upload.filled
  .api__age_upload_btn:before {
    color: var(--color);
  }

  .api__age_upload.filled
  .api__age_upload_btn {
    color: var(--color);
    box-shadow: 0 0 0 .05vw var(--color);
    position: relative;
    order: 2;
    width: 140px;
    height: 118px;
  }

  .api__age_upload.filled
  .api__age_upload_inputs {
    position: relative;
    align-items: flex-start;
    order: 1;
    padding: .5vw 1.5vw;
    flex-flow: column nowrap;
    bottom: unset;
    color: var(--color);
  }

  .api__age_upload.filled
  .api__age_upload__input_url {
    width: 100%;
    text-align: left;
    padding: .5vw;
    background-color: black;
  }

  .api__age_upload.filled
  .api__age_upload__formats {
    width: 100%;
    text-align: left;
    border: none;
    padding-top: .4vw;
  }

  .api__age_result.upload
  .api__age_bg  {
    height: auto;
    padding-bottom: 1vw;
  }

  .api__age_results {
    order: 1;
  }

  .api__age_results_share_item {
    padding: 20px;
  }

  .api__age_results_persons_item {
    padding: 10px 17px;
    font-size: 24px;    
  }

  .api__agege footer.api__age_footer {
    display: flex;
  }
}


@media screen and (min-width: 1300px) {
  .api__age_info,
  .api__age_result,
  .api__age_results {
    flex: unset;
    width: 49%;
  }

  .api__age_info__title {
    font-size: 4vw;
  }

  .api__age_header__home {
    font-size: 1.1vw;
  }

  .api__age_info__descr,
  .api__age_info__samples_count {
    font-size: 1.25vw;
  }

  .api__age_info__samples_arrows {
    font-size: 2vw;
  }

  .api__age_info__samples {
    padding: 0 2.6vw;
  }

  .api__age_info__samples_arrows div {
    width: 2vw;
  }

  .api__age_info__title span:after {
    width: 19.8vw;
    height: 6.5vw;
    left: -1vw;
    bottom: -1.2vw;
  }

  .api__age_header__home:after {
    height: .1vw;
    top: 1.4vw;
  }
  
  .api__age_header__home:hover:after {
    width: 0;
  }

  .api__age_result {
    padding-top: 0;
  }

  .api__age_figure_bg {
    height: 100%;
  }

  .api__age_preview {
    height: 34vw;
  }

  .api__age_preview_bg {
    height: 100%;
  }

  footer.api__age_footer {
    padding-top: 2vw;
    font-size: 1vw;
    line-height: 2vw;
    padding-top: 2vw;
  }

/*  .api__age_footer_copyright,
  .api__age_footer_socials {
    flex: 1 50%;
  }*/

  .api__age_footer_socials_item {
    margin-right: 1vw;
  }

  .api__age_footer_socials_item:after {
    height: .1vw;
    top: 1.5vw;
  }

  .api__age_bg {
    height: 45vw;
    padding-bottom: 0;
  }

  .api__age_upload_btn  {
    width: 7.8vw;
    height: 7.8vw;
    box-shadow: 0 0 0 .05vw #fff;
  }

  .api__age_upload_btn:hover {
    cursor: pointer;
    box-shadow: 0 0 0 .1vw #7000ff;
  }

  .api__age_upload_inputs {
    bottom: 1vw;
  }

  .api__age_upload_text {
    color: #fff;
    width: 19vw;
    font-size: 1.25vw;
  }

  .api__age_upload__input_url {
    width: 19vw;
    padding: .5vw 0;
    font-size: 1.25vw;
  }
  
  .api__age_upload__formats {
    font-size: .9vw;
    border-top: 1px solid var(--color);
    padding-top: 2.5vw;
  }

  .api__age_upload_btn:before {
    font-size: 3vw;
  }

  .api__age_upload.filled {
    height: 7.5vw;
  }

  .api__age_upload.filled
  .api__age_upload_text {
    color: var(--color);
  }

  .api__age_upload.filled
  .api__age_upload_btn {
    order: 2;
    width: 8.2vw;
    height: 7.4vw;
    box-shadow: 0 0 0 0.05vw var(--color);
  }

  .api__age_upload.filled
  .api__age_upload_inputs {
    padding: .5vw 1.5vw;
  }

  .api__age_upload.filled
  .api__age_upload__input_url {
    padding: .5vw;
  }

  .api__age_upload.filled
  .api__age_upload__formats {
    padding-top: .4vw;
  }

  .api__age_results_title {
    padding-top: 5vw;
  }

  .api__age_results_count {
    font-size: 2.7vw;
  }

  .api__age_results_persons_single {
    font-size: 5.6vw;
  }

  .api__age_results_persons_single img {
    height: 4.8vw;
  }

  .api__age_results_main {
    justify-content: space-between;
  }

  .api__age_results_text {
    flex: unset;
    font-size: 1.25vw;
    line-height: 2vw;
    width: 60%;
  }

  .api__age_results_share {
    width: 35%;
    flex: unset;
    font-size: 1.4vw;
    line-height: 1.7vw;
  }

  .api__age_results_share_title {
    align-items: center;
  }

  .api__age_theme {
    width: 3vw;
    height: 3vw;
    position: fixed;
    display: flex;
    left: 1vw;
  }

  .api__age_theme:hover {
    cursor: pointer;
  }

  .api__age_theme:hover .api__age_theme_light{
    animation: light_theme_animation 3s infinite;
  }

  .api__age_theme:hover .api__age_theme_dark{
    animation: dark_theme_animation 3s infinite;
  }

  .api__age_theme div {
    display: flex;
    flex: 1 50%;
    height: 100%;
  }

  .api__age_theme_light {
    background: #fff;
    border-top-left-radius: 3vw;
    border-bottom-left-radius: 3vw;
  }

  .api__age_theme_dark {
    background: #1a1a1a;
    border-top-right-radius: 3vw;
    border-bottom-right-radius: 3vw;
  }

  .api__age_results_persons_item {
    padding: .4vw .7vw;
    font-size: 1.25vw;
    box-shadow:
      .05vw 0 0 0 rgba(255,255,255,.5), 
      0 .05vw 0 0 rgba(255,255,255,.5), 
      .05vw .05vw 0 0 rgba(255,255,255,.5), 
      .05vw 0 0 0 rgba(255,255,255,.5) inset, 
      0 .05vw 0 0 rgba(255,255,255,.5) inset;
  }

  .api__age_about {
  	padding: 4vw 5vw;
  	flex-flow: row wrap;
  }
	
	.api__age_about_title,
  .api__age_about_text {
  	width: 50%;
  }

  .api__age_about_title {
  	font-size: 3.75vw;
  	padding-bottom: 11vw;
  }

  .api__age_about_text {
  	font-size: 1.25vw;
  	padding-top: 2.6vw;
  }

  .api__pushes {
    top: 30vh;
    width: 20vw;
  }

  .api__pushes_message {
    font-size: .8vw;
    padding: .5vw 0;
  }

  .api__age_results_socials  {
    height: 7vw;
  }

  .ya-share2__icon {
    height: 2vw !important;
    width: 2vw !important;
  }

  .api__age_results_persons_single svg {
    height: 3.9vw;
    width: 3.9vw;
    margin-left: 0;
  }
}

@keyframes light_theme_animation {
  0% {background-color: var(--color)}
  50% {background-color: var(--back-color)}
  100% {background-color: var(--color)}
}

@keyframes dark_theme_animation {
  0% {background-color: var(--back-color)}
  50% {background-color: var(--color)}
  100% {background-color: var(--back-color)}
}

.account_credentials_column.client_id_wrap {
	width: 27%;
}

.account_credentials_column.client_id_wrap .client_id_text {
	width: 100%;
	overflow: hidden;
}

/*keywording guide*/

.guide_main ~ footer {
	display: none;
}

@font-face {
	font-family: 'Cutive Mono';
	src: url('../fonts/CutiveMono-Regular.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}

.guide_main {
	position: relative;
	background: #FFF;
	padding-top: 6.48vh;
	box-sizing: border-box;
}

.guide_side_menu {
	position: fixed;
	width: 21.82vw;
	display: flex;
	flex-direction: column;
	margin-right: 7.18vw;
}

.guide_side_menu__link {
	width: 21.82vw;;
	height: 5.67vh;
	display: flex;
	align-items: center;
	padding-left: 2.18vw;
	border:1px solid #000;
	border-width: 1px 1px 0 1px;
	box-sizing: border-box;

	text-decoration: none;
	font-size: 1.04vw;
	color: #000;
}

.guide_side_menu__link:last-child {
	border-width: 1px 1px 1px 1px;
}

.guide_side_menu__link:hover {
	border: 1px solid #7000FF;
	border-width: 1px 1px 0 1px;
	outline: 3px solid #7000FF;
}

.guide_side_menu__link:last-child:hover {
	border: 1px solid #7000FF;
	border-width: 1px 1px 1px 1px;
	outline: 3px solid #7000FF;
}

.guide_side_menu__link.active {
	border: 2px solid #7000FF;
	outline: 1px solid #7000FF;
	padding-left: calc(2.18vw - 1px);
	color: #7000FF;
}

.guide_text_block {
	width: 66vw;
	margin-left: 29vw;
}

.guide_title {
	font-size: 3.12vw;
	margin-bottom: 5.04vh;
}

.article_wrapper::-webkit-scrollbar {
	display: none;
}

.article_wrapper{
	scrollbar-width: none;
}

.guide_article {
	margin-bottom: 3.38vw;
}

.guide_article__title {
	color: #000;
	font-size: 2.18vw;
	font-style: normal;
	font-weight: 400;
	line-height: 99.4%;

	margin-bottom: 2.08vw;
}

.guide_article__table {
	color: #000;
	font-size: 1.25vw;
	line-height: normal;
	margin-bottom: 1.04vw;
	width: 65vw;
}

.guide_article__table td {
	border: 1px solid black;
	padding: 10px;
}

.guide_article__text {
	color: #000;
	font-size: 1.25vw;
	line-height: normal;
	margin-bottom: 1.04vw;
	width: 65vw;
}

.guide_article__subtitle {
	color: #000;
	font-family: Circe;
	font-size: 1.25vw;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 1.04vw;
}

h4.guide_article__text {
	font-size: 1.8vw;
	padding-top: 10px;
}

.guide_article__text li {
	list-style-position: inside;
}

.guide_article__link {
	color: #6A00F9;
	font-family: inherit;
	font-size: 1.25vw;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration-line: underline;
	cursor: pointer;
}

.guide_main .guide_code {
	color: #000;
	font-family: Cutive Mono;
	font-size: 1.25vw;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.copy_id_control {
	margin-left: 10px;
	cursor: pointer;
}


/*Image Captioning Page*/

.image-caption-block.epcontainer,
.image-caption-block .epcontainer {
	padding-left: 30px;
    padding-right: 30px;
}

.image-caption-block ~ footer {
	display: block;
}

.image-caption-block {
	background-color: #ffffff;
}

.image-caption-block.api_main {
	background-color: inherit;
}

.image-caption-block .api_main__decsr {
	max-width: 935px;
}

.image-caption-block .about_block {
	display: flex;
	margin-bottom: 45px;
}

.image-caption-block .about_block.revert {
	display: flex;
	flex-direction: row-reverse;
	margin-bottom: 45px;
}

.image-caption-block .about_block .text_block {
	width: 907px;
	margin-right: 74px;
}

.image-caption-block .about_block.revert .text_block {
	width: 907px;
	margin-left: 74px;
	text-align: end;
	margin-right: unset;
}

.image-caption-block .press_title {
	line-height: 71px;
	width: 857px;
}

.image-caption-block .about_block .text_block .about_text {
	max-width: 907px;
	line-height: 41px;
	margin-top: 17px;
}

.border_block {
	width: 321px;
	height: 393px;
	border: 7px solid #7025FB;
	box-sizing: border-box;
}

.image-caption-block .about_block.revert .border_block {
	width: 321px;
	height: 302px;
	border: 7px solid #7025FB;
	box-sizing: border-box;
	margin-top: 10px;
	margin-right: auto;
}

.image-caption-block .features_items {
	width: 100%;
	margin-bottom: 9px;

	display: flex;
	align-items: flex-start;
	gap: 40px;
}

.image-caption-block .features_item {
	flex-basis: calc((100% - 80px) / 3);
}

.features_block.api_block {
	padding-top: 97px;
}

.image-caption-block .features_item .press_item__title {
	width: 100%;
	padding: 40px 0 10px;
	min-height: 205px;
	margin-bottom: 32px;
}

.image-caption-block .features_item .press_item__text {
	width: 100%;
	max-width: 100%;
}

.image-caption-block .pricing_press_text {
	margin-top: 14px;
	font-size: 28px;
}

.image-caption-block.api_price {
	list-style-type: none;
	padding-bottom: 40px;
	padding-top: 50px;
	margin-top: 0;
	margin-bottom: 0;
	overflow: hidden;
}

.keywording_block4_prices {
	width: 100%;
	margin-top: 34px;
}

.image-caption-block .api_price__item {
	width: 33.33%;
	min-height: 477px;
}

.image-caption-block .api_price__item__name {
	font-size: 36px;
}

.image-caption-block .api_price__item__descr {
	font-size: 20px;
}

.image-caption-block .api_price__item__info {
	font-size: 28px;
	bottom: 15px;
}

.cases-block {
	display: flex;
	flex-direction: column;
}

.cases-block .press_title {
	margin-bottom: 31px;
}

.image-caption-block .list_block {
	display: flex;
	width: 100%;
	gap: 2.7vw;
	margin-bottom: 73px;
	flex-wrap: wrap;
}

.image-caption-block .list_item {
	flex-basis: calc(50% - (2.7vw / 2));
}

.image-caption-block .list_item .press_item__title {
	margin-bottom: 25px;
	max-width: 100%;
}

.image-caption-block .list_item .press_item__text {
	max-width: 100%;
	height: 19.6vw;
	min-height: 255px;
	padding: 2.37vw 1.55vw 1.29vw 2.64vw;
	border: 2px solid #7000FF;
	box-sizing: border-box;
}

.image-caption-block .cases-block .press_text {
	margin-bottom: 28px;
	font-size: 48px;
}


@media screen and (min-width: 1472px) {	
	.image-caption-block .api_main__decsr {
		max-width: 63.5vw;
	}
	
	.image-caption-block .about_block .text_block {
		width: 61.6vw;
		margin-right: 5vw;
	}
	
	.image-caption-block .press_title {
		font-size: 3.26vw;
		line-height: 4.82vw;
		width: 58.22vw;
	}
	
	.image-caption-block .about_block .text_block .about_text {
		max-width: 61.6vw;
		font-size: 1.90vw;
		line-height: 2.78vw;
		margin-top: 1.15vw;
	}
	
	.border_block {
		width: 21.8vw;
		height: 26.47vw;
		border: 7px solid #7025FB;
		box-sizing: border-box;
	}

	.image-caption-block .about_block.revert .border_block {
		width: 21.74vw;
		height: 20.46vw;
		margin-top: .67vw;
	}
	
	.image-caption-block .features_block.api_block {
		padding-top: 97px;
	}
	
	.image-caption-block.features_block .press_title {
		font-size: 3.26vw;
		margin-bottom: 2.71vw;
	}

	
	.image-caption-block .features_item .press_item__title {
		width: 100%;
		max-height: 18.54vw;
		padding: 0;
		min-height: 12vw;
	
		margin-bottom: 1.15vw;
	}
	
	.image-caption-block .features_item .press_item__text {
		width: 100%;
		font-size: 1.63vw;
	}
	
	.image-caption-block .pricing_press_text {
		margin-top: 14px;
		font-size: 1.9vw;
	}
	
	.keywording_block4_prices {
		width: 100%;
		margin-top: 34px;
	}

	.image-caption-block .api_price__item {
		width: 33.33%;
		min-height: 32.98vw;
	}

	.image-caption-block .api_price__item__name {
		font-size: 2.48vw;
	}
	
	.image-caption-block .api_price__item__descr {
		font-size: 1.38vw;
	}
	
	.image-caption-block .api_price__item__info {
		font-size: 1.93vw;

	}
	
	.cases-block .press_title {
		margin-bottom: 31px;
	}
	
	.image-caption-block .list_block {
		gap: 5.4vw;
		margin-bottom: 73px;
	}

	.image-caption-block .list_item {
		flex-basis: calc(50% - (5.4vw / 2));
	}
	
	.image-caption-block .list_item .press_item__title {
		font-size: 2.44vw;
	}
	
	.image-caption-block .list_item .press_item__text {
		font-size: 1.825vw;
		min-height: 17.32vw;
		height: 19.6vw;
		padding: 1.96vw 1.56vw 1.29vw 2.64vw;
	}
	
	.image-caption-block .cases-block .press_text {
		margin-bottom: 1.9vw;
		font-size: 3.26vw;
	}
}


/* Age detection landing*/

.age-detection-block.epcontainer, .age-detection-block .epcontainer {
	padding-left: 30px;
    padding-right: 30px;
}

.age-detection-block ~ footer {
	display: block;
}

.age-detection-block {
	background-color: #ffffff;
}

.age-detection-block.api_main {
	background-color: inherit;
}

.age-detection-block .api_main__decsr {
	max-width: 935px;
}

.age-detection-block .about_block {
	display: flex;
	margin-bottom: 45px;
}

.age-detection-block .about_block .text_block {
	width: 907px;
	margin-right: 74px;
}

.age-detection-block .press_title {
	line-height: 71px;
	width: 857px;
}

.age-detection-block .features_items {
	width: 100%;
	margin-bottom: 9px;

	display: flex;
	align-items: flex-start;
	gap: 40px;
}

.age-detection-block .features_item {
	flex-basis: calc((100% - 80px) / 3);
}

.age-detection-block .features_item .press_item__title {
	width: 100%;
	padding: 40px 0 10px;
	min-height: 205px;
	margin-bottom: 32px;
}

.age-detection-block .features_item .press_item__text {
	width: 100%;
	max-width: 100%;
}

.age-detection-block .about_block .text_block .about_text {
	max-width: 907px;
	line-height: 41px;
	margin-top: 17px;
}

.border_block {
	width: 321px;
	height: 393px;
	border: 7px solid #7025FB;
	box-sizing: border-box;
}

.age-detection_items {
	width: 100%;
	margin-bottom: 9px;

}

.age-detection_item {
	
}

.features_block.api_block {
	padding-top: 97px;
}

.age-detection_item .press_item__title {
	width: 100%;
	max-height: 273px;
	padding: 40px 0 10px;
	min-height: unset;
	margin-bottom: 19px;
}

.age-detection_item .press_item__text {
	width: 100%;
	max-width: 100%;
}

.age-detection-block .pricing_press_text {
	margin-top: 14px;
	font-size: 28px;
}

.age-detection-block.api_price {
	list-style-type: none;
	padding-bottom: 40px;
	padding-top: 50px;
	margin-top: 0;
	margin-bottom: 0;
	overflow: hidden;
}

.keywording_block4_prices {
	width: 100%;
	margin-top: 34px;
}

.age-detection-block .api_price__item {
	width: 33.33%;
	min-height: 477px;
}

.age-detection-block .api_price__item__name {
	font-size: 36px;
}

.age-detection-block .api_price__item__descr {
	font-size: 20px;
}

.age-detection-block .api_price__item__info {
	font-size: 1.89vw;
	bottom: 15px;
}

.cases-block {
	display: flex;
	flex-direction: column;
}

.cases-block .press_title {
	margin-bottom: 31px;
}

.age-detection-block .list_block {
	display: flex;
	width: 100%;
	gap: 2.7vw;
	margin-bottom: 73px;
	flex-wrap: wrap;
}

.age-detection-block .list_item {
	flex-basis: calc(50% - (2.7vw / 2));
}

.age-detection-block .list_item .press_item__title {
	margin-bottom: 25px;
	max-width: 100%;
}

.age-detection-block .list_item .press_item__text {
	max-width: 100%;
	height: 23.6vw;
	min-height: 255px;
	padding: 2.37vw 1.55vw 1.28vw 2.64vw;
	border: 2px solid #7000FF;
	box-sizing: border-box;
}

.age-detection-block .cases-block .press_text {
	margin-bottom: 28px;
	font-size: 48px;
}

.age-detection-block .estest_info__title {
	font-size: 3.25vw;
}

.age-detection-block .estest_result .age_title,
.age-detection-block .estest_result .age_results,
.age-detection-block .estest_result .age_person {
	color: #1a1a1a;
}

.age-detection-block .estest_result .age_person {
	box-shadow: 1px 0 0 0 #1a1a1a, 0 1px 0 0 #1a1a1a, 1px 1px 0 0 #1a1a1a, inset 1px 0 0 0 #1a1a1a, inset 0 1px 0 0 #1a1a1a;
}

.age-detection-block .keywording-block .estest__samples__item img{
	width: 10vw;
	height: unset;
}

.age-detection-block .estest_result-age .estest_result__btn  {
	margin-top: unset;
}

.age-detection-block .keywording-block .estest__samples__item {
	height: unset;
}

/* .age-detection-block .estest_info {
	display: inline-flex;
    flex-direction: column;
    min-height: 518px;
} */


@media screen and (min-width: 1472px) {	
	.age-detection-block .api_main__decsr {
		max-width: 63.5vw;
	}
	
	.age-detection-block .about_block .text_block {
		width: 61.6vw;
		margin-right: 5vw;
	}
	
	.age-detection-block .press_title {
		font-size: 3.26vw;
		line-height: 4.82vw;
		width: 58.22vw;
	}

	.age-detection-block .features_item .press_item__title {
		width: 100%;
		max-height: 18.54vw;
		padding: 0;
		font-size: 2.44vw;
		min-height: 12vw;
	
		margin-bottom: 1.15vw;
	}
	
	.age-detection-block .features_item .press_item__text {
		width: 100%;
		font-size: 1.63vw;
	}
	
	.age-detection-block.features_block .press_title {
		font-size: 3.26vw;
		margin-bottom: 2.71vw;
	}
	
	.age-detection-block .about_block .text_block .about_text {
		max-width: 61.6vw;
		font-size: 1.90vw;
		line-height: 2.78vw;
		margin-top: 1.15vw;
	}
	
	.border_block {
		width: 21.8vw;
		height: 26.47vw;
		border: 7px solid #7025FB;
		box-sizing: border-box;
	}
	
	.features_block.api_block {
		padding-top: 97px;
	}

	.age-detection_item .press_item__title {
		width: 100%;
		max-height: 18.54vw;
		padding: 2.71vw 0 .67vw;
		margin-bottom: 1.28vw;
	}
	
	.age-detection_item .press_item__text {
		font-size: 1.63vw;
	}
	
	.age-detection-block .pricing_press_text {
		margin-top: 14px;
		font-size: 1.9vw;
	}
	
	.keywording_block4_prices {
		width: 100%;
		margin-top: 34px;
	}

	.age-detection-block .api_price__item {
		width: 33.33%;
		min-height: 32.98vw;
	}

	.age-detection-block .api_price__item__name {
		font-size: 2.48vw;
	}
	
	.age-detection-block .api_price__item__descr {
		font-size: 1.38vw;
	}
	
	.age-detection-block .api_price__item__info {
		font-size: 1.93vw;

	}
	
	.cases-block .press_title {
		margin-bottom: 31px;
	}
	
	.age-detection-block .list_block {
		gap: 5.4vw;
		margin-bottom: 73px;
	}

	.age-detection-block .list_item {
		flex-basis: calc(50% - (5.4vw / 2));
	}
	
	.age-detection-block .list_item .press_item__title {
		font-size: 2.44vw;
	}
	
	.age-detection-block .list_item .press_item__text {
		font-size: 1.825vw;
		min-height: 17.32vw;
		/* много теста, в других разделах 19.6 */
		height: 23.6vw;
		padding: 2.37vw 1.56vw 1.29vw 2.64vw;
	}
	
	.age-detection-block .cases-block .press_text {
		margin-bottom: 1.9vw;
		font-size: 3.26vw;
	}

	.age-detection-block .estest_info {
		display: inline-flex;
		flex-direction: column;
		min-height: 35.1vw;
	}

	.age-detection-block .keywording_estest.estest_result {
		margin-bottom: 8.5vw;
	}

	.estest_results.desktop_version .keywording-block .estest_upload { 
		height: 5.88vw;
	}

	.age-detection-block .keywording-block .estest__samples__item img{
		width: 10vw;
		height: unset;
	}
	
	.age-detection-block .keywording-block .estest__samples__item {
		height: unset;
	}

	.age-detection-block .keywording_estest.estest_result {
		margin-bottom: 4vw;
	}
}

/*ONBOARDING */

.account_block {
	position: relative;
}

.onboarding_wrap {
	padding-left: 20.5vw;
	padding-top: 9vw;
	padding-bottom: 1vw;
	position: relative;
}

.onboarding_title {
	color: #000;
	font-family: Circe;
	font-size: 2.08vw;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 45% */
}

.checkbox_wrapper {
	display: flex;
	flex-direction: column;
	gap: 2.34vw;
	margin-top: 3.75vw;
}

.onboarding_checkbox {
	display: flex;
	width: 18.43vw;
	height: 4.425vw;
	padding: 0 1.38vw;
	box-sizing: border-box;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;

	border: 2px solid #1A1A1A;
}

.checkbox_title {
	color: #1A1A1A;
	font-family: Circe;
	font-size: 1.92vw;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 50% */
}

.outer_checkbox_toggle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.65vw;
	height:  1.65vw;
	box-sizing: border-box;
	border: 1px solid #1A1A1A;
}

.inner_checkbox_toggle {
	width: 1.2vw;
	height: 1.2vw;
}

.onboarding_checkbox.checked .inner_checkbox_toggle {
	background-color: #7000FF;
}

.next_btn {
	position: relative;
	display: flex;
	width: 9.75vw;
	height: 4.43vw;
	align-items: center;
	justify-content: center;
	border: 2px solid #000;
	background: #FFF;

	color: #000;
	font-family: Circe;
	font-size: 2.08vw;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 45% */

	margin-top: 4.95vw;
	margin-bottom: 9.2vw;
}

.next_btn:hover {
	border: 2px solid #7000FF;
	outline: 3px solid #7000FF;
}

.next_btn.disabled {
	color: #DCDFE3;
	border: 1px solid #000;
}

.next_btn.disabled:hover {
	border: 1px solid #000;
	outline: unset;
}

.next_btn img {
	width: 20px;
	height: 20px;
	margin-left: 5px;
}


.next_btn .spinner,
.next_btn .everypixel_cross,
.next_btn .inside {
	width: 3vw;
	height: 3vw;
}

.next_btn .spinner .everypixel_cross span {
    background: #1a1a1a;
    display: block;
    height: 0.5vw;
    opacity: 0;
    position: absolute;
    width: 0.5vw;
}

.next_btn .everypixel_cross .fifth {
    right: 1.22vw;
    top: 1.25vw;
}

.next_btn .everypixel_cross .eighth {
	bottom: 1.2vw;
    left: 1.2vw;
}
.next_btn .everypixel_cross .third {
	left: 1.2vw;
    top: 1.2vw;
}
.next_btn .everypixel_cross .fourth {
	top: 1.2vw;
    right: 1.2vw;
}
.next_btn .everypixel_cross .nineth {
	bottom: 1.2vw;
    right: 1.2vw;
}


.arrow_svg {
	stroke: #000;
}

.arrow_svg.disabled {
	stroke: #DCDFE3;
}

/*HEADER*/

.header_block {
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	top: 1.6vw;
	left: 0;
	padding:0 2.8vw;
	width: 100%;
}

.header_block__back_btn {
	display: flex;
	align-items: center;
	color: #000;
	font-family: Circe;
	font-size: 2.08vw;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 45% */

	cursor: pointer;
}

.header_block__back_btn img {
	width: 1.61vw;
	margin-right: 5px;
	margin-bottom: 1.5px;
}

.step_block {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: .7vw;
}

.step_text {
	color: #000;
	font-family: Circe;
	font-size: 1.34vw;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 72% */
}

.step_toggle_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: .7vw;
	padding: 0 7px;
	width: auto;
	height: 1.4vw;
	flex-shrink: 0;

	border: 1px solid #000;
	background: #FFF;
}

.step_toggle {
	width: 3.81vw;
	height: .48vw;

	border: 0.3px solid #000;
	background: #FFF;
}

.step_toggle.active {
	background: #7000FF;
	border:unset
}


/* org block*/


.content_wrapper {
	display: flex;
	flex-direction: column;
	gap: 5.15vw;
}

.onboarding_input_wrapper {
	position: relative;
	width: 33vw;
	height: 2.77vw;
	margin-top: 3vw;
}

.onboarding_input_wrapper.last_name {
	margin-top: 2vw;
}

.onboarding_input_wrapper:nth-child(2) {
	margin-bottom: 0;
}

.onboarding_label {
	position: absolute;
	top: .5vw;
	left: 0;

	color: #A8A8A9;
	font-family: Circe;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 51.429% */
	font-size: 1.82vw;
}

.onboarding_input {
	width: 100%;
	height: 2.77vw;
	border: none;
	border-bottom: 1px solid black;
	padding-bottom: 10px;

	color: #1A1A1A;
	font-family: Circe;
	font-size: 1.82vw;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 51.429% */


	background-color: inherit;
}

.checkbox_wrapper__small {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 36.11vw;
	height: 8.5vw;
	gap: 1.38vw;
	margin-top: 2.29vw;
}

.checkbox_wrapper__small .onboarding_checkbox {
	width: 15.27vw;
	height: 3.47vw;
}

.checkbox_wrapper__small .checkbox_title {
	font-size: 1.28vw;
}

.checkbox_wrapper__small .outer_checkbox_toggle {
	width: 1.11vw;
	height: 1.11vw;
}
.checkbox_wrapper__small .inner_checkbox_toggle {
	width: .7vw;
	height: .7vw;
}

/*dropdown*/

.dropdown_list {
	position: absolute;
	visibility: hidden;
	width: 33vw;
	background-color: #fff;
	transition: visibility 0.2s;
	z-index: 100;
}

input:focus ~ .dropdown_list {
	visibility: visible;
}

.dropdown_item {
	display: flex;
	align-items: center;
	width: 100%;
	height: 3.6vw;
	padding: 0 1.18vw;
	border: 1px solid #000;
	border-width: 0 1px 1px 1px;
}

.dropdown_item:hover {
	outline: 3px solid #7000FF;
	z-index: 100;
	border-color: #7000FF;
}

.dropdown_item.other {
	flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 7px;
	background: #D7D9FF;
}

.dropdown_title {
	color: #1A1A1A;
	font-family: Circe;
	font-size: 1.82vw;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 51.429% */
}

.dropdown_item.other .dropdown_title {
	color: #1A1A1A;
	font-family: Circe;
	font-size: .95vw;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 90% */
}

.dropdownt_misc {
	color: #1A1A1A;
	font-family: Circe;
	font-size: 1.07vw;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 120% */
}


/*NEW ACCOUNT*/

:root {
    --border-primary: #1A1A1A;
    --surface-primary: #FFF;
    --surface-secondary: #F2F3F4;
    --text-body: #1A1A1A;
    --text-placeholder: #A8A8A9;
    --border-action-hover: #7000FF;
    --border-error: #FF6565;
    --black: #1A1A1A;
    --surface-selected-light: #FAF6FF;
    --text-accent: #7000FF;
    --text-headings: #1A1A1A;
    --text-description: #888;
    --neutral: #888;
    --border-light: #F2F3F4;
    --frame-width: 760px;

    --icon-secondary: #7000FF;
    --border-secondary: #DCDFE3;
    --text-link: #7000FF;
    --Text-Widht: 560px;
    --error-hovered: #E80000;
    --text-disabled: #DCDFE3;
}

.account_body_new {
    font-style: normal;
    font-weight: 400;
	
}

.account_body_new .btn-default {
	margin: 0;
}

.account_body_new .row {
	display: flex;
    justify-content: center;
    margin-top: 10px;
	top: 3vh;
	position: fixed;
    left: 50%;
    transform: translate(-50%, 10px);
    z-index: 9999;
}

.account_body_new .row .col-md-12{
	width: 100%;
}
.account_body_new .row .col-md-12 .alert {
	border: 1px solid transparent;
    border-radius: unset;
    margin-bottom: 20px;
    padding: 15px;
	animation: cssAnimation 0s ease-in 3s forwards;
    animation-fill-mode: forwards;
}
@keyframes cssAnimation {
    to {
        display: none;
    }
}

.account_body_new .row .col-md-12 .alert-success {
    background-color: #00B08A;
    border-color: #00B08A;
    color: #ffffff;
}

.account_body_new  .close {
    color: #FFFFFF;
	opacity: 1;
	margin-left: 5px;
}

.df {
	display: flex;
}

.account_body_new.container {
	width: unset;
} 

.account_btn {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    font-size: 18px;

    border: 2px solid var(--border-primary);
    background: var(--surface-primary);
    color: var(--text-body);
}

.account_btn:hover {
    outline: 4px solid var(--border-action-hover);
    border: 2px solid var(--border-action-hover);
}

.modal_submit_btn {
	width: 100%;
	margin-top: 20px;
}

/*INPUTS*/

input[type= "number" ]::-webkit-inner-spin-button, input[type= "number" ]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
  }

.input_wrapper {
    position: relative;
    width: 364px;
    height: 50px;
}

.input_wrapper select {
	all: unset;
}

.input_wrapper select {
	display: block;
	position: relative;
	border: 1px solid var(--border-primary);
    background: var(--surface-primary);

    width: 364px;
    height: 50px;
    padding: 16px 15px;
    box-sizing: border-box;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 100% */
    color: var(--text-body);
}

.input_placeholder {
    border: 1px solid var(--border-primary);
    background: var(--surface-primary);

    width: 364px;
    height: 50px;
    padding: 16px 15px;
    box-sizing: border-box;

    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 100% */
    color: var(--text-body);
}

.input_placeholder:focus,
.input_wrapper select:focus {
    outline: 4px solid var(--border-action-hover);
    border: 1px solid var(--border-action-hover);
}

.input_wrapper.active .input_placeholder{
    padding: 20px 15px 3px;
}

.input_wrapper .input_wrapper_arrow {
	position: absolute;
	height: 15px;
    right: 15px;
    top: 17px;
    width: 15px;
    z-index: 2;
}

.input_wrapper select:focus ~ .input_wrapper_arrow {
	transform: rotate(180deg);
}

.input_wrapper.active  select{
    padding: 25px 15px 3px;
}

.input_wrapper.error .input_placeholder{
    border: 1px solid var(--border-error);
}

.input_wrapper.disabled .input_placeholder{
    background: var(--surface-secondary);
    border:none;
}

.input_label {
    position: absolute;
    top: 16px;
    left: 15px;
    text-transform: none;
	z-index: 2;

    color: var(--text-placeholder);
}

.input_wrapper.active .input_label {
    top: 7px;
    text-transform: uppercase;
    font-size: 10px;
}

.input_wrapper.error .input_label {
    color: var(--border-error);
}

/*side menu*/

.side_block {
    min-width: 260px;
}

.profile_wrapper {
    display: flex;
    padding: 20px 30px;
}

.user_avatar {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 11px 13px 6px 13px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border: 1px solid var(--border-action-hover);
}

.user_name {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    color: var(--text-body);
    font-size: 14px;
    line-height: 18px;
}

.side_menu {
    list-style: none;
}

.side_menu_item {
    padding: 16px 30px;
}

.item_link {
	text-decoration: none;

	color: var(--text-body);

    font-family: Inter;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.side_menu_item.selected,
.side_menu_item:hover {
    border-left: 4px solid var(--border-action-hover);
    background: var(--surface-selected-light);
	padding: 16px 30px 16px 26px;
}

.side_menu_sub_item.selected,
.side_menu_sub_item:hover {
    border-left: 4px solid var(--border-action-hover);
    background: var(--surface-selected-light);
	padding: 16px 45px 16px 41px;
}

.side_menu_sub_item {
    padding: 16px 45px;
}

.side_menu_sub_item .item_link {
    font-size: 12px;
}

/**/

.profile_content_block {
	width: 840px;
    padding: 50px 0 50px 80px;
}

.projects_btns .account_btn {
	padding-top: 12px;
	padding-bottom: 12px;
}

.instrumental_block {
	width: 994px;
}

.profile_form_wrapper {
	display: flex;
	gap: 30px;
}

.page_title {
    margin-bottom: 40px;

    color: var(--text-headings);
    font-size: 48px;
    line-height: 54px;
}

.balance_section {

}

.section_title {
    color: var(--text-headings);
    font-size: 28px;
    line-height: 42px; /* 150% */

    margin-bottom: 20px;
}

.section_text {
    color: var(--text-description);
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 10px;
}

.count_text {
    color: var(--text-headings);
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 20px;
}

.section_btn {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    border: 2px solid var(--border-primary);
    background: var(--surface-primary);

    color: var(--border-primary);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.section_btn:hover {
    outline: 4px solid var(--border-action-hover);
    border: 2px solid var(--border-action-hover);
}

.table_title_wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: 80px 170px 150px 135px;
    margin-bottom: 10px;
}

.table_title_wrapper div {
    color: var(--neutral);

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

#keysListTarget:not(:empty) + form {
	display: none;
}

.grid_container {
    display: grid;
    gap: 20px;
	grid-template-columns: 80px 170px 150px 135px 105px 16px;
    border-top: 1px solid var(--border-light);
    border-bottom: 1px solid var(--border-light);
    padding: 10px 0;
    width: 760px;
	align-items: center;
}

.summary_info_wrapper {
	display: flex;
	align-items: center;
}

.summary_info_wrapper  img{
  	display: none;
	margin-left: 5px;
	cursor: pointer;
}

.summary_info_wrapper:hover  img{
	display: block;
}

.summary_keys_name,
.summary_keys_email,
.summary_keys_id,
.summary_keys_secret {
    display: inline-block;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.summary_keys_name {
	width: 80%;
}

.summary_keys_update_btn {
    display: flex;
    padding: 7px 10px;
    justify-content: center;
    align-items: center;
	white-space: nowrap;

    border: 1px solid var(--border-primary);
    background: var(--surface-primary);
}

.summary_keys_update_btn:hover {
	outline: 4px solid var(--border-action-hover);
    border: 1px solid var(--border-action-hover);
}

.section_splitter {
    width: var(--frame-width, 760px);
    height: 1px;
    background: var(--border-light);
    margin: 40px 0;
}

.chart_section {
	display: flex;
    flex-direction: column;
	gap: 60px;
    width: 760px;
    margin-bottom: 40px;
	overflow: unset;
}

.chart_section_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.chart_section_title {
    color: var(--text-headings);
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
}

.chart_section__options_block {
	display: flex;
	margin-bottom: 20px;
}

.date_picker__date {
    display: flex;
    align-items: center;
    color: var(--text-description);
    font-size: 14px;
    line-height: 18px;
    margin-right: 20px;
}

.date_picker__wrapper {
    display: flex;
}

.dropdown_wrapper {
    position: relative;
    width: 140px;
    height: 32px;
    box-sizing: border-box;

    display: flex;
	justify-content: space-between;
    padding: 7px 10px;
	cursor: pointer;

    border: 1px solid var(--border-secondary);
    background: var(--surface-primary);
}

.dropdown_wrapper.active {
    outline: 4px solid var(--border-action-hover);
}

.dropdown_wrapper .dropdown {
    position: absolute;
    top: 42px;
    left: 0;
    list-style: none;
	z-index: 100;
	display: none;
}

.dropdown_wrapper p {
	white-space: nowrap;
}

.dropdown_wrapper img {
	margin-left: 5px;
}

.dropdown_wrapper img.reverse {
	transform:  rotate(180deg);
}

.dropdown.active {
 	display: block;
}

.dropdown__item {
    display: flex;
    padding: 7px 10px;
    width: 130px;
    height: 32px;
    box-sizing: border-box;

    border-right: 1px solid var(--border-primary);
    border-bottom: 1px solid var(--border-primary);
    border-left: 1px solid var(--border-primary);
    background: var(--surface-primary);
	cursor: pointer;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown__item:first-child {
    border: 1px solid var(--border-primary);
}

.dropdown__item:hover {
	outline: 3px solid #7000FF;
}

.role_wrapper {
    position: relative;
    width: fit-content;
    height: auto;
    box-sizing: border-box;

    display: flex;
    padding: 7px 15px;
    border: 1px solid var(--border-secondary);
    background: var(--surface-primary);
}

.option_dropdown__text {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.summary_export_btn {
  	margin-left: auto;
    margin-bottom: 10px;
}

.summary_tabs_wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.summary_tab {
    color: var(--text-headings);
	cursor: pointer;
    font-size: 18px;
    line-height: 22px;
}
.summary_tab.active {
    border-bottom: 2px solid var(--border-action-hover);
}

.canvas_wrapper {
    display: flex;
}

.amount_items {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top: -7px;
    margin-right: 10px;
}
.amount_item {
    width: 25px;
    height: 18px;
    color: var(--text-placeholder);
    text-align: right;

    /* Body/xxs */
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 180% */
}

.day_items {
    display: flex;
    gap: 3px;
    margin-top: 2px;
    margin-left: 25px;
    justify-content: space-between;
    width: 748px;
}

.day_item {
    width: 25px;
    height: 18px;

    color: var(--text-placeholder);
    text-align: center;

    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.total_usage_wrapper {
    display: flex;
}

.first_line {
    display: flex;
    width: 365px;
    height: 20px;
    padding: 5px;
    align-items: center;
    box-sizing: border-box;
    margin-right: 10px;


    background: var(--surface-secondary);
}

.progress_line {
    height: 10px;
    position: relative;
    z-index: 1;
    background: var(--icon-secondary);
}

.total_usage_amount {
    color: var(--text-body);
    font-size: 14px;
    line-height: 18px;
}

/*Api keys page*/

.api_keys_block .grid_container {
    display: grid;
    gap: 20px;
	grid-template-columns: 80px 170px 150px 135px 105px 16px;;
    border-top: 1px solid var(--border-light);
    border-bottom: 1px solid var(--border-light);
    padding: 10px 0;
    width: 760px;
}

.api_keys_img {

}

.keys_delete_mobile_btn {
	display: none;
	place-self: center;
    margin-left: 10px;
	cursor: pointer;
	border: none;
	background-color: inherit;
}

.grid_container:hover .keys_delete_mobile_btn {
	display: block;
}

.api_keys__text {
    color: var(--text-description);
    width: 520px;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 40px;
}

.create_key_btn {
    display: flex;
    margin-top: 40px;
    width: 252px;
    padding: 16px 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    color: var(--text-body);
    text-align: center;;
    font-size: 18px;
    line-height: 18px;

    border: 2px solid var(--border-primary);
    background: var(--surface-primary);
}

.create_key_btn:hover {
    outline: 4px solid var(--border-action-hover);
    border: 2px solid var(--border-action-hover);
}

.account_modal_window {
    position: fixed;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9000;
    background: var(--surface-primary);
}

.account_modal_window.opened {
	display: flex;
	overflow: scroll;
}

.create_modal.opened {
    display: flex;
}

.modal_block {
    width: 484px;
    height: auto;
    padding: 60px;
    box-sizing: border-box;

    border: 1px solid var(--border-primary);
    background: var(--surface-primary);
}

.modal_block.red { 
	border: 1px solid #EE4343;
}


.account_modal_window .modal_title {
    color: var(--text-headings);

    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
	text-align: justify;

    margin-bottom: 10px;
}

.modal_text {
    color: var(--text-description);

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */

    margin-bottom: 30px;
}

.modal_btn {
    display: flex;
    width: 364px;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    border: 2px solid var(--border-primary);
    background: var(--surface-primary);

    color: var(--text-body);
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.modal_btn.disabled  {
	color: #DCDFE3;
}
.modal_btn.disabled:hover  {
	outline: none;
	border: 2px solid var(--border-primary);
}

.balance_form .modal_btn.disabled {
	color: inherit;
	opacity: .2;
	pointer-events: none;
}

.modal_btn.black {
	border: 2px solid var(--border-primary);
	color: var(--text-body, #1A1A1A);
}

.modal_btn:hover,
.api_keys_page .cancel_btn:hover {
    outline: 4px solid var(--border-action-hover);
    border: 2px solid var(--border-action-hover);
}

.modal_btn_wrapper {
    display: flex;
    gap: 10px;
}

.delete_modal .modal_block {
    height: auto;
}

.delete_modal.members_page {
	border: 1px solid var(--border-error);
}

.close_modal_img {
    position: absolute;
    right: 30px;
    top: 30px;
}

.keys_email_dropdown__wrapper {
	width: 100%;
	position: relative;
}

.keys_email_dropdown__label {
	font-size: 14px;
	cursor: pointer;
}

.keys_email_dropdown__label::after {
	background-image: url(../i/account_new/pencil.png);
    content: "";
    height: 12px;
    position: absolute;
    top: 40%;
    width: 12px;
    transform: translateY(-50%);
    margin-left: 10px;
    background-size: 100% 100%;
}

.keys_email_dropdown__items {
	position: absolute;
    top: 42px;
    left: 0;
    list-style: none;
	z-index: 100;
	display: none;
	width: 100%;
}

.keys_email_dropdown__wrapper.active .keys_email_dropdown__items {
	display: block;
}

.keys_email_dropdown__item {
    display: block;
    padding: 7px 10px;
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid var(--border-primary);
	border-width: 1px 1px 0 1px;
    background: var(--surface-primary);
	cursor: pointer;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.keys_email_dropdown__item:last-child {
	border-width: 1px 1px 1px 1px;
}

.keys_email_dropdown__item:hover {
	outline: 3px solid var(--border-action-hover);;
}


/*Balance Page*/

.payment_section {
    margin-top: 40px;
}

.balance_payment_subtext {
    color: var(--text-description);
    font-size: 14px;
    line-height: 18px; /* 128.571% */
}

.balance_alert_header {
	width: 560px;
	margin-bottom: 30px;
}

.text_link {
    text-decoration: none;
    color: var(--text-link);
    font-size: 14px;
}

.balance_payment_text {
    margin-top: 30px;

    color: var(--text-body);
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 30px;

}

.balance_alert_save_btn {
	margin-top: 30px;
}

.auto_payment_block {
    margin-bottom: 30px;
    max-width: 560px;
}

.balance_page .auto_payment_block .input_wrapper,
.balance_page .auto_payment_block .input_placeholder{
    width: 318px;
}

.balance_page .auto_payment_block .input_wrapper {
    margin-top: 10px;
}

.block_subtitle {
    max-width: 560px;
    margin-bottom: 10px;

    color: var(--text-headings);
    font-size: 18px;
    line-height: 22px;
}

.billing_modal {
    display: none;
    height: auto;
    min-height: 100vh;
}

.billing_modal.opened {
    display: flex;
}

.billing_modal .modal_block{
    height: 780px;
    margin: 122px 0;
    padding: 40px 60px;
}

.balance_page .modal_text {
    margin-bottom: 20px;
}

.card_grid {
    display: grid;
    gap: 10px;
    grid-template-columns: 200px 90px 65px ;
    width: 376px;
}

.balance_page .input_placeholder,
.balance_page .input_wrapper
{
    width: 100%;
}

.form_block_title {
    margin-bottom: 10px;
    color: var(--text-headings);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.balance_form__card_block {
    margin-bottom: 20px;
}

.balance_form__city_block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.city_grid {
    display: grid;
    gap: 10px;
    grid-template-columns: 177.5px 177.5px;
}

.balance_modal {
    display: none;
    min-height: 100vh;
}

.balance_modal.opened {
    display: flex;
	overflow: auto;
}

.balance_modal .modal_block{
    padding: 40px 60px;
}

.current_card_block {
    margin: 30px 0;
}

.current_card {
    width: 180px;
    padding: 15px 20px;

    border: 1px solid var(--border-secondary);
}

.current_card__number {
    margin-bottom: 4px;

    color: var(--text-headings);
    font-size: 14px;
    line-height: 18px;
}

.current_card__date {
    color: var(--text-description);
    font-size: 14px;
    line-height: 18px;
}

.other_pay_btn,.paypal_btn {
    border: 2px solid var(--border-primary);
    color: var(--text-body);
}

.paypal_btn {
    padding: 0 30px;
    height: 50px;
}

.top-up {
	color: var(--text-accent);
	border: 2px solid var(--border-action-hover);
}

/*PAYMENT METHOD PAGE*/

.card_wrapper {
    display: flex;
	flex-wrap: wrap;
    gap: 20px;
	margin-bottom: 20px;
}

.payment_card {
    width: 180px;
    padding: 15px 20px;
    box-sizing: border-box;

    border: 1px solid var(--border-primary);
}
.payment_card.default {
    border: 1px solid var(--border-action-hover);
}

.payment_card__info {
    margin-bottom: 15px;
    font-size: 14px;
}

.card_info__name {
    margin-bottom: 4px;
}

.card_info__date {

}

.payment_card__btn_wrapper {
    display: flex;
    justify-content: space-between;

}

.card_status {
    font-size: 12px;
}

.card_status.default {
    color: var(--text-placeholder);
}

.card_status:not(.card_status.default):hover {
    color: var(--text-accent);
}

.dlt_card_btn {
    font-size: 12px;
}

.dlt_card_btn:hover {
    color: var(--error-hovered);
}

/*BILLING HISTORY PAGE*/

.content_block__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 760px;
    margin-bottom: 20px;
	flex-wrap: wrap;
}

.billing_history_block .content_block__header {
	align-items: flex-start;
}

.billing_history_block .content_block__header .page_title {
	margin-bottom: 20px;
}

.history_text_wrapper {
	flex-grow: 1;
}

.content_block__header .page_title {
    margin: 0;
}

.content_block__subheader {

}

.content_block__projects {
	font-size: 28px;
}

.content_block__info {
	padding: 20px 0 30px;
	font-size: 14px;
	color: #888;
}


.history_dwnld_btn {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;

    border: 2px solid var(--border-primary);
    background: var(--surface-primary);
}

.billing_history_block .section_text{
    margin-bottom: 40px;
}

.history_grid {
    display: grid;
    grid-template-columns: 135px 100px 130px 100px 35px;
    gap: 40px;
    border-bottom: 1px solid var(--border-light);
}

.grid__dwld_link .mobile_text {
	display: none;
}

.info_row {
    padding: 20px 0;
}

.grid_title {
    color: var(--text-placeholder);
    font-size: 12px;
    line-height: 18px; /* 150% */
    letter-spacing: 1px;
    text-transform: uppercase;

    margin-bottom: 10px;
}

.grid_info_item {
    font-size: 18px;
    line-height: 18px;
}

.grid__dwld_link {
    font-size: 18px;
    line-height: 18px;
}

.grid__dwld_link:hover {
    color: var(--text-accent);
}

/*BILLING INFO PAGE*/

.info_form_section {
    margin-bottom: 40px;
}

.info_form_title {
    color: var(--text-headings);
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
}

.info_form_text {
    color: var(--text-description);
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
}

.info_form_section .input_wrapper  {
    margin-bottom: 10px;
}

.info_form_section .city_grid {
    display: grid;
    grid-template-columns: 177.5px 177.5px;
    gap: 10px;
}

.info_form_section .city_grid .input_wrapper,
.info_form_section .city_grid .input_placeholder {
    width: 100%;
}

.info_form_error {
	color: red;
	padding-bottom: 3px;
	display: none;
}

.info_form_tips[data-error*="1"] div:nth-child(1),
.info_form_tips[data-error*="2"] div:nth-child(2),
.info_form_tips[data-error*="3"] div:nth-child(3),
.info_form_tips[data-error*="4"] div:nth-child(4) {
	color: red;
}

.info_change_btn {
    width: fit-content;
    border: 2px solid var(--border-primary);
    background: var(--surface-primary);
	color: var(--border-primary);
}

.info_change_btn.black{
	border: 2px solid var(--border-primary);
	color: var(--border-primary);
}

.info_change_btn.black:hover{
    border: 2px solid var(--border-action-hover);
    outline: 4px solid var(--border-action-hover);
}

.info_change_btn.hidden {
	display: none;
}	

/*MEMBERS PAGE*/

.members_block .content_block__header {
    margin-bottom: 40px;
}

.content_block__header.vertical {
	display: block;
}

.content_block__header.vertical .page_title {
	margin-bottom: 20px;
}

.content_block__header.vertical .create_org_btn {
	margin-top: 40px;
}

.members_block .account_btn {
	padding: 16px 40px;
	white-space: nowrap;
}

.invite_toggle {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.toggle_wrapper {
    display: flex;
    width: 49px;
    height: 30px;
    padding: 7px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 20px;
    border: 1px solid var(--border-primary);
}

.toggle_wrapper.active {
    justify-content: flex-end;
}

.inner_toggle {
    width: 16px;
    height: 16px;
    background: var(--text-body);
}

.toggle_wrapper.active .inner_toggle {
    background: var(--icon-secondary);
}

.members_block .invite_toggle .section_text {
    width: 560px;
    font-size: 14px;
    line-height: 18px;
}

.link_block {
    display: flex;
    margin-bottom: 40px;
}
.link_block.align-center {
    align-items: center;
	justify-content: space-between;
}

.link_block .input_placeholder {
    border: 1px solid var(--border-secondary);
}

.copy_link_btn {
    display: flex;
    padding: 16px 20px;
    height: 50px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    border: 2px solid var(--border-primary);
    background: var(--surface-primary);
}

.copy_link_btn:hover {
    position: relative;
    outline: 4px solid var(--border-action-hover);
    border: 2px solid var(--border-action-hover);
}

.members_list_grid {
    display: grid;
    grid-template-columns: 372px 314px 74px;
    align-items: center;
    border-bottom: 1px solid var(--border-light, #F2F3F4);
}

.members_list_grid__title {
    color: var(--text-description);
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.members_block .profile_wrapper {
    display: flex;
    padding: 15px 0;
}

.user_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
}

.user_card__name-wrapper {
    display: flex;
    align-items: center;
}

.user_card__name-wrapper .user_name {
    color: var(--text-body, #1A1A1A);
    font-size: 14px;
    line-height: 18px;

    margin-right: 5px;
    margin-left: 0;
}

.user_card__info {
    background: var(--border-primary, #1A1A1A);
    color: #FFF;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;

    padding: 0 10px;
}

.user_card__email {
    color: var(--text-placeholder, #A8A8A9);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.12px;
}

.members_block .dropdown_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.members_block .dropdown__item {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 70px;
    padding: 7px 10px;
}

.members_block .dropdown__item .dropdown_item__title {
    color: var(--text-body, #1A1A1A);

    /* Body/sm */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.members_block .dropdown__item .dropdown_item__text {
    color: var(--text-placeholder, #A8A8A9);

    /* Body/xs */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.12px;
}

.members_leave_btn {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
    width: 74px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid var(--border-secondary, #DCDFE3);
    background: var(--surface-primary, #FFF);
    color: var(--text-disabled, #DCDFE3);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.members_delete_btn {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
    width: 74px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid var(--border-primary, #1A1A1A);
    background: var(--surface-primary, #FFF);
    color: var(--text-body, #1A1A1A);
    text-align: center;
 
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.members_delete_btn:hover,
.dropdown_wrapper:hover{
    outline: 4px solid var(--border-action-hover);
    border: 1px solid var(--border-action-hover);
}


.invite_modal .modal_block {
    height: auto;
}


.invite_modal_grid {
    display: grid;
    gap: 11px;
    grid-template-columns: auto 16px;
    margin-bottom: 10px;
}

.invite_modal_grid__title {
    color: var(--text-description, #888);

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 180% */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.invite_modal_grid .input_wrapper,
.invite_modal_grid .input_placeholder {
    width: 98%;
}

.invite_modal_grid .dropdown_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 123px;
    height: 50px;
    padding: 15px 16px;
    box-sizing: border-box;
    border: 1px solid var(--border-primary, #1A1A1A);
    background: var(--surface-primary, #FFF);
}

.invite_modal_grid .dropdown_wrapper p{
    font-size: 18px;
	white-space: nowrap;
}

.remove_member_cross {
    place-self: center;
}

.modal_add_members_btn {
    color: var(--text-accent, #7000FF);
 
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    margin-top: 15px;
}

.send_invitations_btn {
    display: flex;
    width: fit-content;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
	white-space: nowrap;

    border: 2px solid var(--border-primary, #7000FF);
    background: var(--surface-primary, #FFF);
    color: var(--text-body, #7000FF);


    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 100% */
}

.send_invitations_btn:hover {
    outline: 4px solid var(--border-action-hover);
    border: 2px solid var(--border-action-hover);
}

.invite_form .invite_toggle{
    align-items: flex-start;
}

.invite_form .toggle_wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 48px;
    height: 16px;
    padding: 2px;
    box-sizing: border-box;
    margin-right: 12px;
}

.invite_form .toggle_wrapper.active{
    justify-content: flex-end;
}

.invite_form .inner_toggle{
    width: 11px;
    height: 11px;
    box-sizing: border-box;
}

.invite_form .section_text{
    color: var(--text-description, #888);

    /* Body/sm */

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.invite_form  .link_block {
    margin-bottom: 0;
}

.invite_form .link_block .input_wrapper {
    width: 250px;
}

.invite_form .link_block .input_placeholder {
    width: 250px;
    border: 1px solid var(--border-primary);
}

.invite_form .link_block .copy_link_btn {
    width: 113px;
}

.mobile_logo_wrapper {
	display: none;
}

.invite-modal-text {
	position: absolute;
    color: #FFFFFF;
    z-index: 1000;
    top: 181px;
    left: 134px;
    font-size: 31px;
	line-height: 90%;
}

.usage_block .api_keys__text {
	width: 560px;
}

.modal-auth .modal_window.modal-join {
	width: 400px;
	height: 650px;
}

.modal-register .modal_window.modal-join .modal_title {
	text-align: center;
}

.password_form_header_wrapper {
	display: flex;
	justify-content: space-between;
	width: 364px;
}

.purple_text_btn {
	color: var(--border-action-hover);
	font-size: 14px;
	display: none;
	cursor: pointer;
}

.purple_text_btn.active  {
	display: block;
}

.StripeElement {
    box-sizing: border-box;
    height: 50px;
    padding: 16px 15px;
    border: 1px solid black;
    border-radius: 1px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}
.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;}
.StripeElement--invalid {
    border-color: #ff6565;
}
.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
